import { createAsyncThunk } from '@reduxjs/toolkit'
import request, { GETOption, PUTOption } from '../../utils/requestAxios'
import { GREETING_API, ENDPOINT_KEY } from '../../utils/api'

export const getGreetingItem = createAsyncThunk(
  'greeting/getGreetingItem',
  async () => {
    const response = await request(
      `${GREETING_API}?${ENDPOINT_KEY}`,
      GETOption()
    )
    return response
  }
)

export const updateGreetingItem = createAsyncThunk(
  'greeting/updateGreetingItem',
  async ({ message, smartReply }, thunkAPI) => {
    const { dispatch } = thunkAPI
    const payload = {
      message,
      smart_replies: smartReply
    }
    const response = await request(
      `${GREETING_API}?${ENDPOINT_KEY}`,
      PUTOption(payload)
    )
    await dispatch(getGreetingItem())
    return response
  }
)
