import { createSlice } from '@reduxjs/toolkit'
import { getGreetingItem, updateGreetingItem } from './action'

export const greetingSlice = createSlice({
  name: 'greeting',
  initialState: {
    greetingItem: {
      status: 'idle',
      response: {},
      data: {
        message: '',
        smartReply: [],
        headerAction: { time: '', author: '' }
      }
    },
    updateGreeting: {
      status: 'idle',
      response: {}
    }
  },
  reducers: {
    clearState(state) {
      state.greetingItem.response = {}
      state.updateGreeting.response = {}
      state.updateGreeting.status = 'idle'
    },
    resetGreetingItemStatus(state) {
      state.greetingItem.status = 'idle'
    }
  },
  extraReducers: {
    [getGreetingItem.pending]: (state) => {
      state.greetingItem.status = 'loading'
    },
    [getGreetingItem.fulfilled]: (state, action) => {
      state.greetingItem.status = 'succeeded'
      state.updateGreeting.status = 'idle'
      state.greetingItem.data.message = action.payload.message
      state.greetingItem.data.smartReply = action.payload.smart_replies
      state.greetingItem.data.headerAction.time = '-'
      state.greetingItem.data.headerAction.author = '-'
    },
    [getGreetingItem.rejected]: (state, action) => {
      state.greetingItem.status = 'failed'
      state.greetingItem.data.message = ''
      state.greetingItem.data.smartReply = []
      state.greetingItem.data.headerAction.time = '-'
      state.greetingItem.data.headerAction.author = '-'
      state.greetingItem.response = action
    },
    [updateGreetingItem.pending]: (state, action) => {
      state.updateGreeting.status = 'loading'
      state.greetingItem.data.message = action.meta.arg.message
      state.greetingItem.data.smartReply = action.meta.arg.smartReply
    },
    [updateGreetingItem.fulfilled]: (state, action) => {
      state.updateGreeting.status = 'succeeded'
      state.updateGreeting.response = action.payload
    },
    [updateGreetingItem.rejected]: (state, action) => {
      state.updateGreeting.status = 'failed'
      state.updateGreeting.response = action
    }
  }
})

export const selectGreetingItem = (state) => state.greeting.greetingItem
export const selectUpdateGreetingResponse = (state) =>
  state.greeting.updateGreeting

export const { clearState, resetGreetingItemStatus } = greetingSlice.actions

export default greetingSlice.reducer
