import { createSlice } from '@reduxjs/toolkit'

export const mainSlice = createSlice({
  name: 'main',
  initialState: {
    snackBar: {
      isActive: false,
      type: 'warn',
      text: '',
      duration: 10000,
      showAt: undefined
    },
    notificationBar: {
      isActive: false,
      type: '',
      text: '',
      duration: 10000,
      path: '',
      onButtonClick: undefined,
      buttonText: 'text',
      modalElement: undefined
    },
    timeoutModal: {
      isShow: false
    }
  },
  reducers: {
    setSnackBar(state, action) {
      state.snackBar.isActive = action.payload.isActive || false
      state.snackBar.type = action.payload.type
      state.snackBar.text = action.payload.text
      state.snackBar.duration = action.payload.duration
      state.snackBar.showAt = action.payload.showAt
    },
    setNotificationBar(state, action) {
      state.notificationBar.isActive = action.payload.isActive
      state.notificationBar.type = action.payload.type
      state.notificationBar.text = action.payload.text
      state.notificationBar.duration = action.payload.duration
      state.notificationBar.forcedClose = action.payload.forcedClose
      state.notificationBar.path = action.payload.path
      state.notificationBar.onButtonClick = action.payload.onButtonClick
      state.notificationBar.buttonText = action.payload.buttonText
      state.notificationBar.modalElement = action.payload.modalElement
    },
    setTimeoutModal(state, action) {
      state.timeoutModal.isShow = action.payload.isShow
    }
  }
})

export const {
  setSnackBar,
  setNotificationBar,
  setTimeoutModal
} = mainSlice.actions

export const selectSnackBar = (state) => state.main.snackBar
export const selectNotificationBar = (state) => state.main.notificationBar
export const selectTimeoutModal = (state) => state.main.timeoutModal

export default mainSlice.reducer
