import { createAsyncThunk } from '@reduxjs/toolkit'
import request, {
  POSTOption
} from '../../utils/requestAxios'
import { SEGMENT_API } from '../../utils/api'

export const uploadSegment = createAsyncThunk(
  'segment/uploadSegment',
  async (data) => {
    const formData = new FormData()
    formData.append('file', data.file)
    formData.append('segment_name', data.segment_name)
    formData.append('mode', data.mode)
    const response = await request(
      `${SEGMENT_API}`,
      POSTOption(formData)
    )
    return response
  }
)
