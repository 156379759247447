import styled, { css } from 'styled-components'
import {
 boxShadow, setColor, buttonStyle, setFlex
} from '../../globalStyled'

const keyframe = css`
  @keyframes fadein {
    from {
      opacity: 0;
      transform: translateY(10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @keyframes fadeout {
    from {
      opacity: 1;
      transform: translateY(10px);
    }
    to {
      opacity: 0;
      transform: translateY(0);
    }
  }
`

export const SnackbarWrapper = styled.div`
  width: 100vw;
  ${setFlex()};
  display: block;
  position: absolute;
  z-index: ${(props) => (props.isActive ? '2' : '-1')};
  left: 0;
  bottom: 7rem;
  transform: scale(${(props) => (props.isActive ? '1' : '0')});
`

export const Bar = styled.div`
  ${keyframe};
  transition: opacity 0.2s linear;
  opacity: ${(props) => (props.isActive ? '1' : '0')};
  background-color: ${(props) => props.backgroundColor};
  width: 36.8rem;
  max-height: 6rem;
  ${boxShadow};
  font-size: 1.4rem;
  color: ${setColor.white};
  padding: 1rem;
  border-radius: 0.5rem;
  margin: auto;
  line-height: 1.5;
  ${setFlex('space-between')};
  .text {
    display: grid;
    grid-template-columns: ${(props) =>
      (props.type === 'error' ? '1fr 6rem' : '1fr')};
    width: 100%;
    margin: 0 1.2rem;
  }
  .btn {
    &-x {
      ${buttonStyle};
      align-self: flex-start;
    }
    &-action {
      ${buttonStyle};
      text-decoration: underline;
      text-align: center;
    }
  }
  .icon {
    width: 2rem;
    height: 2rem;
    align-self: flex-start;
  }
`
