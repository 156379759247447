import styled, { css } from 'styled-components'
import {
 setColor, setFlex, boxShadow, setTransition
} from '../../globalStyled'

export const OverlayWrapper = styled.div`
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: ${(props) => `${props.backgroundColor}`};
  z-index: 10;
  ${setFlex()};
  position: absolute;
  visibility: ${(props) => (props.isShow ? 'visible' : 'hidden')};
  transform: scale(${(props) => (props.isShow ? '1' : '0')};);
  ${setTransition('0.5s')}
`
export const CustomModalWrapper = styled.div`
  ${boxShadow};
  @keyframes bonce {
    0% {
      transform: scale(0.8);
      opacity: 0;
    }
    50% {
      transform: scale(1.2);
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
  border-radius: 0.4rem;
  overflow: hidden;
  animation: bonce 0.2s ease-in-out;
  padding: ${(props) => (props.noPadding ? 0 : '3rem 2.5rem;')};
  background-color: ${setColor.white};
  ${setFlex('space-between')};
  flex-direction: column;
  color: ${setColor.brownishGrey};
  text-align: center;
  .icon {
    max-height: 4rem;
    max-width: 4rem;
    margin-bottom: 0.9rem;
  }
  .title {
    font-size: 1.6rem;
    font-weight: 700;
    line-height: 1;
    margin-bottom: 1rem;
    word-break:break-all;
  }
  .description {
    font-size: 1.4rem;
    font-weight: normal;
    line-height: 1.5;
    word-wrap: break-word;
    white-space: pre-line;
    &__group {
      margin-top: 1.5rem;
      margin-bottom: 1rem;
    }
  }
  .button {
    &__wrapper {
      ${setFlex('space-evenly')};
      width: 80%;
      margin-top: 1.5rem;
    }
  }
  ${setTransition()};
  height: ${(props) => `
    ${props.height}
  `};
  width: ${(props) => `
    ${props.width}
  `};
  ${(props) => css`
    ${props.customStyle}
  `}
`
