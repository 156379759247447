import { createAsyncThunk } from '@reduxjs/toolkit'
import request, { GETOption, PUTOption } from '../../utils/requestAxios'
import { PROFANITY_API } from '../../utils/api'

export const getProfanityItem = createAsyncThunk(
  'profanity/getProfanityItem',
  async () => {
    const response = await request(`${PROFANITY_API}`, GETOption())
    return response
  }
)

export const updateProfanityItem = createAsyncThunk(
  'profanity/updateProfanityItem',
  async (keywords) => {
    const response = await request(`${PROFANITY_API}`, PUTOption({ keywords }))
    return response
  }
)
