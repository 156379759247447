import styled from 'styled-components'

export const MainWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  display: grid;
  grid-template-rows: ${(props) =>
    (props.isShowNoti ? '4rem 1fr 4rem' : '0rem 1fr 4rem')};
`
export const BodyWrapper = styled.div`
  width: 100vw;
  display: grid;
  grid-template-columns: 21rem 1fr;
`
