import styled from 'styled-components'
import { setColor, setFlex } from '../../globalStyled'

export const FooterWrapper = styled.div`
  font-family: Roboto;
  height: 4rem;
  width: 100%;
  background-color: ${setColor.primaryGreen};
  color: ${setColor.white};
  font-size: 1.2rem;
  ${setFlex()};
  .version {
    position: absolute;
    bottom: 5px;
    left: 5px;
    color: ${setColor.primaryGreen};
  }
`
