import React from 'react'
import PropTypes from 'prop-types'
import { generateTestId } from '../../utils/tools'

const SmartReplyInput = ({
  word,
  index,
  smartReplyInputRef,
  onChange,
  onKeyUp,
  onRemove
}) => (
  <div className='smartReply__input--wrapper'>
    <input
      value={word.text}
      onChange={onChange}
      onKeyUp={onKeyUp}
      ref={smartReplyInputRef}
      placeholder='ใส่ข้อความสมาร์ทรีพลาย'
      className='smartReply__input'
      maxLength='25'
      data-testid={`input-smartReply-${index}`}
      id={generateTestId('greetingMessageEditor', 'input', 'addSmartReply', index)}
    />
    <button
      type='submit'
      className='smartReply__input--btn_remove'
      onClick={onRemove}
      data-testid={`button-smartReply-delete-${index}`}
      id={generateTestId('greetingMessageEditor', 'button', 'deleteSmartReply')}
    >
      <div
        className='xIcon'
        id={generateTestId('greetingMessageEditor', 'img', 'deleteSmartReply')}
      />
    </button>
  </div>
)

export default SmartReplyInput

SmartReplyInput.propTypes = {
  word: PropTypes.shape({
    text: PropTypes.string
  }),
  index: PropTypes.number,
  smartReplyInputRef: PropTypes.shape({}),
  onChange: PropTypes.func,
  onKeyUp: PropTypes.func,
  onRemove: PropTypes.func
}
