import { format } from 'date-fns'
import { th } from 'date-fns/locale'

export const generateTestId = (
  page = '',
  component = '',
  name = '',
  additional
) =>
  `testid-${page}-${component}-${name}${additional ? `-${additional}` : ''}`

export const _roundingMinute = (time, min = 5) => {
  const coeff = 1000 * 60 * min
  const date = new Date(time)
  const rounded = new Date(Math.ceil(date.getTime() / coeff) * coeff)
  return rounded
}

export const convertHexToRGBA = (hexCode, opacity) => {
  let hex = hexCode.replace('#', '')
  if(hex.length === 3) {
    hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`
  }

  const r = parseInt(hex.substring(0, 2), 16)
  const g = parseInt(hex.substring(2, 4), 16)
  const b = parseInt(hex.substring(4, 6), 16)

  return `rgba(${r},${g},${b},${opacity})`
}

export const formatDay = (date) =>
  format(date, 'd', {
    locale: th
  })

export const formatMonth = (date) =>
  format(date, 'MMMM', {
    locale: th,
    timeZone: 'Asia/Bangkok'
  })

export const formatYear = (date) =>
  format(date, 'yyyy', {
    timeZone: 'Asia/Bangkok'
  })

export const formatTime = (date) =>
  format(date, 'HH:mm', {
    timeZone: 'Asia/Bangkok'
  })

export const formatDate = (date) =>
  `${formatDay(date)} ${formatMonth(date)} ${formatYear(date)}`

export const getLengthObject = (object) => Object.keys(object).length

export const isEmptyObject = (object) => (
  object && Object.keys(object).length === 0 && object.constructor === Object
)

export const formatNumber = (number) => (
  number || number === 0 ? number.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') : '-'
  )

export const setIsBlockSideMenuClick = (isBlock) => {
  localStorage.setItem('isBlockRouting', isBlock)
}

export const getIsBlockSideMenuClick = () => localStorage.getItem('isBlockRouting') === 'true'
