import styled from 'styled-components'
import {
  setColor,
  borderStyle,
  setFlex,
  buttonStyle,
  setTransition,
  headerStyle,
  scrollBarStyle
} from '../../globalStyled'
import xIconGrey from '../../imgs/close_grey.svg'
import xIconGreen from '../../imgs/close_green.svg'
import binIconGreen from '../../imgs/delete_green.svg'
import binIconGrey from '../../imgs/delete_grey.svg'

export const GreetingEditorWrapper = styled.div`
  display: grid;
  grid-template-rows: 7.6rem 1fr;

  .wrapper {
    padding: 2.4rem;
  }

  .smartReply {
    &__container {
      border: ${borderStyle};
      height: fit-content;
      margin-top: 1.2rem;
    }
    &__header {
      ${headerStyle};
      padding: 0 2.4rem;
      height: 5.5rem;
      align-items: center;
      &--icon {
        ${setFlex('space-between')};
        &_btn {
          ${buttonStyle};
          img {
            width: 2.4rem;
            height: 2.4rem;
          }
          .binIcon {
            background-image: url(${binIconGrey});
            width: 2.4rem;
            height: 2.4rem;
            ${setTransition('0.05s')}
          }
          &:hover .binIcon {
            background-image: url(${binIconGreen});
          }
        }
      }
    }
    &__wrapper {
      ${setFlex(undefined, 'stretch')};
      flex-direction: column;
    }
    &__input {
      height: 4.6rem;
      outline: none;
      border: none;
      width: 100%;
      font-size: 1.4rem;
      &:focus {
        outline: none;
      }
      &::placeholder {
        color: ${setColor.veryLightPink};
      }
      &--wrapper {
        width: 100%;
        padding: 0 2.4rem;
        ${setFlex('space-between')};
        height: 6.2rem;
      }
      &--btn {
        &_remove {
          ${buttonStyle};
          background-color: ${setColor.transparent};
          .xIcon {
            background-image: url(${xIconGrey});
            width: 2.4rem;
            height: 2.4rem;
            ${setTransition('0.05s')}
          }
          &:hover .xIcon {
            background-image: url(${xIconGreen});
          }
        }
        &_add {
          font-size: 1.4rem;
          ${buttonStyle};
          background-color: ${setColor.grayWhite};
          height: 4.6rem;
          color: ${setColor.brownishGrey};
          text-align: left;
          padding: 0 2.4rem;
        }
      }
    }
  }
`

export const GreetingTextarea = styled.div`
 .message {
    &__container {
      border: ${borderStyle};
      height: fit-content;
    }
    &__header {
      ${headerStyle};
      &-count {
        font-size: 1.4rem;
        color: ${setColor.brownGrey};
        font-weight: normal;
        text-align: end;
      }
    }
    &__textarea {
      border: none;
      width: 100%;
      white-space: pre-wrap;
      padding: 2rem 2.4rem;
      resize: none;
      height: 10.3rem;
      font-size: 1.4rem;
      line-height: 1.5;
      ${scrollBarStyle};
      &:focus {
        outline: none;
        border: none;
      }
      &::placeholder {
        color: ${setColor.veryLightPink};
      }
    }
`
