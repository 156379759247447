import React from 'react'
import PropTypes from 'prop-types'
import backIcon from '../../imgs/icon-left.svg'
import Tooltip from '../Tooltip'
import infoIcon from '../../imgs/info.svg'
import loadingGif from '../../imgs/button-loading.gif'
import { generateTestId } from '../../utils/tools'
import {
  ButtonWrapper,
  PrimaryButtonWrapper,
  FlatButtonWrapper,
  BackButtonWrapper,
  TextButtonWrapper,
  InfoWrapper
} from './styled'

export const PrimaryButton = ({
  onClick,
  children,
  key,
  customStyle,
  isLoading = false,
  disabled = false,
  testid,
  loadingItem = <img data-testid='loading-gif' src={loadingGif} alt='' width='40' />,
  id,
  name,
  type = 'submit'
}) => (
  <PrimaryButtonWrapper
    onClick={onClick}
    key={key}
    customStyle={customStyle}
    disabled={disabled || isLoading}
    isLoading={isLoading}
    data-testid={testid || 'primary-button'}
    id={id || generateTestId('button', 'primary', name)}
    name={name}
    type={type}

  >
    {isLoading ? loadingItem : children}
  </PrimaryButtonWrapper>
)
PrimaryButton.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  key: PropTypes.string,
  customStyle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool,
  testid: PropTypes.string,
  loadingItem: PropTypes.element,
  id: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string
}

export const FlatButton = ({
  onClick,
  children,
  key,
  customStyle,
  isLoading = false,
  disabled = false,
  testid,
  loadingItem = 'loading',
  id,
  name
}) => (
  <FlatButtonWrapper
    onClick={onClick}
    key={key}
    customStyle={customStyle}
    disabled={disabled || isLoading}
    isLoading={isLoading}
    data-testid={testid || 'flat-button'}
    id={id || generateTestId('button', 'flat', name)}
    name={name}
  >
    {isLoading ? loadingItem : children}
  </FlatButtonWrapper>
)
FlatButton.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string, PropTypes.elementType]),
  key: PropTypes.string,
  customStyle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool,
  testid: PropTypes.string,
  loadingItem: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string
}

export const TextButton = ({
  onClick,
  children,
  key,
  customStyle,
  isLoading = false,
  disabled = false,
  testid,
  loadingItem = 'loading',
  id,
  name
}) => (
  <TextButtonWrapper
    onClick={onClick}
    key={key}
    customStyle={customStyle}
    disabled={disabled || isLoading}
    isLoading={isLoading}
    data-testid={testid || 'text-button'}
    id={id || generateTestId('button', 'text', name)}
    name={name}
  >
    {isLoading ? loadingItem : children}
  </TextButtonWrapper>
)
TextButton.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string, PropTypes.instanceOf(TextButton)]),
  key: PropTypes.string,
  customStyle: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.array]),
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool,
  testid: PropTypes.string,
  loadingItem: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string
}

export const BackButton = ({
  onClick,
  key,
  customStyle,
  isLoading = false,
  disabled = false,
  testid,
  id
}) => (
  <BackButtonWrapper
    onClick={onClick}
    key={key}
    customStyle={customStyle}
    disabled={disabled || isLoading}
    isLoading={isLoading}
    data-testid={testid || 'back-button'}
    id={id || generateTestId('button', 'back', 'back')}
  >
    <img
      src={backIcon}
      alt=''
      id={generateTestId('button', 'img', 'back')}
    />
    กลับ
  </BackButtonWrapper>
)
BackButton.propTypes = {
  onClick: PropTypes.func,
  key: PropTypes.string,
  customStyle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool,
  testid: PropTypes.string,
  id: PropTypes.string
}

export const InfoButton = ({ text, id, theme }) => (
  <Tooltip
    text={text}
    theme={theme}
    id={generateTestId('button', 'tooltip', 'info')}
  >
    <InfoWrapper
      type='submit'
      className='info-InfoWrapper'
      data-testid='info-button'
      id={id}
    >
      <img
        src={infoIcon}
        alt=''
        id={generateTestId('button', 'img', 'info')}
      />
    </InfoWrapper>
  </Tooltip>
)

InfoButton.propTypes = {
  text: PropTypes.string,
  id: PropTypes.string,
  theme: PropTypes.string
}

const Button = (props) => {
  const {
 children, onClick, block, testid
} = props
  return (
    <ButtonWrapper
      {...props}
      block={block}
      onClick={onClick}
      data-testid={testid}
    >
      {children}
    </ButtonWrapper>
  )
}

export default Button

Button.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array, PropTypes.string]),
  onClick: PropTypes.func,
  block: PropTypes.bool,
  testid: PropTypes.string
}
