import React from 'react'
import { ReportWrapper } from './styled'

const Report = () => (
  <ReportWrapper>
    <div className='header'>รีพอร์ต</div>
    Report stuff here
  </ReportWrapper>
)

export default Report
