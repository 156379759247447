/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { connect, useDispatch, useSelector } from 'react-redux'
import { setUserProfile, selectUserProfile } from './appSlice'
import { selectUserInfo } from '../LoginPage/loginSlice'
import auth from '../../firebase'
import { getUserInfo } from './actions'
import App from '.'
import {
  getToken,
  getStatus,
  clearToken,
  setStatus
} from '../../utils/tokenService'
import { setTimeoutModal } from '../MainPage/MainSlice'

export const AppConnector = () => {
  const [authUser, setAuthUser] = useState(null)
  const userInfoFromLogin = useSelector(selectUserInfo)
  const userProfile = useSelector(selectUserProfile)
  const dispatch = useDispatch()

  useEffect(() => {
    setAuthUser('load')
    auth.onAuthStateChanged((user) => {
      setAuthUser(user)
      if(user && !getToken()) {
        dispatch(setUserProfile(userProfile))
      }
    })
  }, [userInfoFromLogin])

  useEffect(() => {
    if(getStatus() === 'true') {
      dispatch(getUserInfo())
    }
  }, [getStatus()])

  useEffect(() => {
    if(userProfile?.status === 'failed') {
      dispatch(setTimeoutModal({ isShow: true }))
    }
  }, [userProfile?.status])

  useEffect(() => {
    const _handleUnauthorized = () => {
      axios.interceptors.response.use((response) => response, (error) => {
        if(error.response.status === 401) {
          dispatch(setTimeoutModal({ isShow: true }))
        }
        throw error
      })
    }
    _handleUnauthorized()
  }, [])

  const logout = () => {
    auth.signOut().then(() => {
      window.location.reload()
      clearToken()
      setStatus(false)
    })
  }

  return (
    <App
      auth={authUser}
      userProfile={userProfile}
      logout={logout}
    />
  )
}

const mapStateToProps = (state) => ({
  ...state
})

export default connect(mapStateToProps)(AppConnector)
