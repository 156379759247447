import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Tooltip } from 'antd'
import { setColor } from '../../globalStyled'
import './style.scss'

const ToolTip = ({
  text,
  children,
  placement = 'bottom',
  id,
  theme = 'light',
  detectChildWidth = false,
  customEleStyle
}) => {
  const [wordState, setWordState] = useState('')

  useEffect(() => {
    setWordState(text)
  }, [text])

  const getWidth = (e) => {
    if(e && detectChildWidth) {
      if(e.offsetWidth >= 150) {
        setWordState(text)
      } else {
        setWordState('')
      }
    }
  }

  return (
    <Tooltip
      title={wordState}
      placement={placement}
      color={theme.includes('light') ? setColor.white : ''}
      overlayClassName={theme}
      id={id}
    >
      <div ref={getWidth} style={{ ...customEleStyle }} data-testid='tooltip-children-wrapper'>
        {children}
      </div>
    </Tooltip>
  )
}

export default ToolTip

ToolTip.propTypes = {
  text: PropTypes.string,
  children: PropTypes.shape({}),
  placement: PropTypes.string,
  id: PropTypes.string,
  theme: PropTypes.string,
  detectChildWidth: PropTypes.bool,
  customEleStyle: PropTypes.shape({})
}
