/* istanbul ignore file */
import { createGlobalStyle, css } from 'styled-components'
// import kanitRegular from './fonts/Kanit-Regular.ttf'
// import lobotoRegular from './fonts/Roboto-Regular.ttf'
import 'antd/dist/antd.css'
import 'react-datepicker/dist/react-datepicker.css'
import './fonts/fonts.scss'

export const setFlex = (x = 'center', y = 'center') => css`
  display: flex;
  justify-content: ${x};
  align-items: ${y};
`
export const setColor = {
  cobalt: '#1c4d9c',
  jungleGreen: '#007a53',
  cerulean: '#0072d9',
  emerald: '#00a651',
  cherryRed: '#ed1c24',
  orange: '#f36f21',
  yellow: '#fab000',
  brownishGrey: '#666666',
  brownGrey: '#999999',
  borderGrey: '#e0e0e0',
  veryLightPink: '#cccccc',
  grayWhite: '#f5f5f5',
  grayText: '#c1c1c1',
  black: '#000000',
  white: '#ffffff',
  primaryGreen: '#02a550',
  transparent: 'transparent'
}

export const boxShadow = css`
  box-shadow: 0 0 20px 0 rgba(153, 153, 153, 0.4);
`
export const scrollBarStyle = css`
  ::-webkit-scrollbar {
    width: 0.8rem;
    height: 0.8rem;
  }
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 1rem ${setColor.transparent};
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: ${setColor.borderGrey};
  }
`
export const setTransition = (
  time = '0.2s',
  ele = 'all',
  animate = 'ease-in'
) => css`
  transition: ${time} ${ele} ${animate};
`
export const borderStyle = `1px solid ${setColor.borderGrey};`
export const buttonStyle = css`
  border: none;
  outline: none;
  cursor: pointer;
  background-color: ${setColor.transparent};
  &:disabled {
    cursor: not-allowed;
  }
`
export const MainBodyLayout = css`
  display: grid;
  grid-template-rows: 6.5rem 1fr;
  .body__wrapper {
    &.column {
      &-1 {
      }
      &-2 {
        display: grid;
        grid-template-columns: 1fr 31.5rem;
      }
    }
  }
  .header {
    font-size: 2rem;
    text-transform: uppercase;
    font-weight: bold;
    color: ${setColor.black};
    padding: 1.4rem 2.4rem;
    border-bottom: ${borderStyle};
    ${setFlex('center', 'flex-start')};
    flex-direction: column;
  }
`
export const headerStyle = css`
  border-bottom: ${borderStyle};
  color: ${setColor.black};
  padding: 2rem 2.4rem;
  font-weight: bold;
  display: grid;
  grid-template-columns: 1fr 7rem;
  font-size: 1.6rem;
  height: 5.6rem;
`

export const GlobalStyle = createGlobalStyle`
  :root {
    --cobalt: #1c4d9c;
    --jungle-green: #007a53;
    --cerulean: #0072d9;
    --emerald: #00a651;
    --cherry-red: #ed1c24;
    --orange: #f36f21;
    --yellow: #fab000;
    --brownish-grey: #666666;
    --brown-grey: #999999;
    --very-light-pink: #cccccc;
    --graywhite: #f5f5f5;
    --white: #ffffff;
  }
  *{
    padding: 0;
    margin:0;
    box-sizing:border-box;
    font-family: 'Kanit', sans-serif;
  }
  html{
    font-size:62.5%;
  }
  body{
    font-size: 1.6rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: ${setColor.brownishGrey};
  }
  h1, h2, h3, h4, h5, h6 {
    font-family: 'Kanit', sans-serif;
    color: ${setColor.brownishGrey};
    letter-spacing: normal;
    font-style: normal;
    font-stretch: normal;
  }
 
  h1 {
    font-size: 4.8rem;
    font-weight: bold;
    line-height: 0.92;
  }
  h2 {
    font-size: 3.6rem;
    font-weight: bold;
    line-height: 0.89;
  }
  h3 {
    font-size: 2.4rem;
    font-weight: bold;
    line-height: 0.83;
  }
  h4 {
     font-size: 1.8rem;
    font-weight: bold;
    line-height: 0.89;
  h5 {
    font-size: 1.6rem;
    font-weight: normal;
    line-height: 1.5;
  }
  h6 {
    font-size: 1.6rem;
    font-weight: bold;
    line-height: 0.88;
  }

 
${'' /* @font-face {
  font-family: 'Kanit';
  font-style: normal;
  font-weight: 400;
  src: url(${kanitRegular});
  src: local('Kanit'), local('Roboto-Regular'),
       url(${kanitRegular}) format('truetype'),
}
@font-face {
  font-family: 'Kanit';
  font-style: bold;
  font-weight: 500;
  src: url(${kanitRegular});
  src: local('Kanit'), local('Roboto-Regular'),
       url(${kanitRegular}) format('truetype'),
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url(${lobotoRegular});
  src: local('Kanit'), local('Roboto-Regular'),
       url(${lobotoRegular}) format('truetype'),
} */}
`
