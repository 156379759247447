import { createSlice } from '@reduxjs/toolkit'
import {
  getBroadcastList,
  getSentBroadcastList,
  deleteBroadcast,
  getFailedBroadcastList,
  resendBroadcastRequest
} from './action'

const _handleExtraReducer = (action, dataState) => ({
  [action.pending]: (state, { meta }) => {
    state[dataState].status = 'loading'
    state[dataState].data.currentPage = meta.arg?.page || 1
    state.selectedDates.start = meta.arg?.startDate || null
    state.selectedDates.end = meta.arg?.endDate || null
  },
  [action.fulfilled]: (state, { payload, meta }) => {
    state[dataState].status = 'succeeded'
    state[dataState].data.broadcast = payload.broadcast
    state[dataState].data.totalPage = payload.total_pages
    state[dataState].data.currentPage = payload.current_page
    state.selectedDates.start = meta.arg?.startDate || null
    state.selectedDates.end = meta.arg?.endDate || null
  },
  [action.rejected]: (state, { error }) => {
    state[dataState].status = 'failed'
    state[dataState].data.broadcast = []
    state[dataState].data.totalPage = 1
    state[dataState].data.currentPage = 1
    state[dataState].error = error
  }
})

const _handleResetState = (state, dataState) => {
  state[dataState].status = 'idle'
  state[dataState].data.broadcast = []
  state[dataState].data.totalPage = 1
  state[dataState].data.currentPage = 1
  state[dataState].error = undefined
  _resetSelectedDates(state)
}

const _resetSelectedDates = (state) => {
  state.selectedDates.start = null
  state.selectedDates.end = null
}

export const broadcastListSlice = createSlice({
  name: 'broadcast',
  initialState: {
    selectedDates: {
      start: null,
      end: null
    },
    scheduledList: {
      status: 'idle',
      data: {
        broadcast: [],
        totalPage: 1,
        currentPage: 1
      },
      error: undefined
    },
    draftList: {
      status: 'idle',
      data: {}
    },
    sentList: {
      status: 'idle',
      data: {
        broadcast: [],
        totalPage: 1,
        currentPage: 1
      },
      error: undefined
    },
    failedList: {
      status: 'idle',
      data: {
        broadcast: [],
        totalPage: 1,
        currentPage: 1
      },
      error: undefined
    },
    deleteBroadcast: {
      status: 'idle',
      data: {}
    },
    resendBroadcast: {
      status: 'idle',
      data: {}
    }
  },
  reducers: {
    resetScheduledListStatus(state) {
      _handleResetState(state, 'scheduledList')
    },
    resetSentListStatus(state) {
      _handleResetState(state, 'sentList')
    },
    resetDraftListStatus(state) {
      _handleResetState(state, 'draftList')
    },
    resetFailedListStatus(state) {
      _handleResetState(state, 'failedList')
    },
    resetDeleteBroadcastStatus(state) {
      _handleResetState(state, 'deleteBroadcast')
    },
    resetResendBroadcastStatus(state) {
      _handleResetState(state, 'resendBroadcast')
    }
  },
  extraReducers: {
    ..._handleExtraReducer(getBroadcastList, 'scheduledList'),
    ..._handleExtraReducer(getSentBroadcastList, 'sentList'),
    ..._handleExtraReducer(getFailedBroadcastList, 'failedList'),
    ..._handleExtraReducer(deleteBroadcast, 'deleteBroadcast'),
    ..._handleExtraReducer(resendBroadcastRequest, 'resendBroadcast')
  }
})

export const selectScheduledList = (state) => state.broadcast.scheduledList
export const selectDraftList = (state) => state.broadcast.draftList
export const selectSentList = (state) => state.broadcast.sentList
export const selectFailedList = (state) => state.broadcast.failedList
export const selectSelectedDates = (state) => state.broadcast.selectedDates
export const selectDeleteBroadcast = (state) => state.broadcast.deleteBroadcast
export const selectResendBroadcast = (state) => state.broadcast.resendBroadcast

export const {
  resetScheduledListStatus,
  resetSentListStatus,
  resetDraftListStatus,
  resetFailedListStatus,
  resetDeleteBroadcastStatus,
  resetResendBroadcastStatus
} = broadcastListSlice.actions

export default broadcastListSlice.reducer
