import React from 'react'
import PropTypes from 'prop-types'
import { Route, Switch, Redirect } from 'react-router-dom'
import { MainWrapper, BodyWrapper } from './styled'
import Footer from '../../components/Footer'
import SideNav from '../../components/SideNav'
import GreetingMessage from '../GreetingMessagePage/GreetingMessageConnector'
// import Broadcast from '../BroadcastListPage/BroadcastListConnector'
// import CreateBroadcast from '../CreateBroadcastPage/CreateBroadcastConnector'
// import EditBroadcast from '../EditBroadcastPage/EditBroadcastConnector'
// import BroadcastDetail from '../BroadcastDetailPage/BroadcastDetailConnector'
// import ProfanityWord from '../ProfanityWordPage/ProfanityConnector'
// import Segment from '../SegmentPage/SegmentConnector'
import Report from '../ReportPage'
import NotificationBar from '../../components/NotificationBar'
import { OverlayCustomModal } from '../../components/CustomModal'

const MainPage = ({
  history,
  notificationBarProps,
  timeoutModalProps,
  setTimeoutModal,
  logout,
  userInfo
}) => {
  const isShowNotificationBar =
    notificationBarProps.isActive &&
    notificationBarProps.path === window.location.pathname
  const timeOutModal = {
    confirmButton: 'ตกลง',
    onConfirm: () => {
      setTimeoutModal({ isShow: false })
      logout()
    },
    title: 'หมดเวลาการใช้งาน',
    description: 'กรุณาเข้าสู่ระบบใหม่อีกครั้ง'
  }

  const getRoute = () => {
    // the comment below is for disable menu from task: https://sertiscorp.atlassian.net/browse/CGW-667
    let route = (
      <Switch>
        {/* <Route exact path='/broadcast' component={Broadcast} />
        <Route exact path='/broadcast/create' component={CreateBroadcast} />
        <Route exact path='/broadcast/edit' component={EditBroadcast} />
        <Route exact path='/broadcast/detail' component={BroadcastDetail} />
        <Route exact path='/broadcast/:activeList' component={Broadcast} /> */}
        <Route exact path='/greeting' component={GreetingMessage} />
        {/* <Route exact path='/profanity' component={ProfanityWord} />
        <Route exact path='/segment' component={Segment} /> */}

        <Route>
          <Redirect to='/greeting' />
        </Route>
      </Switch>
    )
    if(userInfo?.userInfo?.user_group === 'management-livesupport') {
      route = (
        <Switch>
          <Route exact path='/report' component={Report} />
          <Route>
            <Redirect to='/report' />
          </Route>
        </Switch>
      )
    }
    return route
  }

  return (
    <MainWrapper isShowNoti={isShowNotificationBar}>
      <NotificationBar
        text={notificationBarProps.text}
        type={notificationBarProps.type}
        isActive={isShowNotificationBar}
        duration={notificationBarProps.duration}
        path={notificationBarProps.path}
        onButtonClick={notificationBarProps.onButtonClick}
        buttonText={notificationBarProps.buttonText}
        modalElement={notificationBarProps.modalElement}
      />
      <BodyWrapper>
        <SideNav userInfo={userInfo} history={history} logout={logout} />
        {getRoute()}
      </BodyWrapper>
      <Footer />
      <OverlayCustomModal {...timeOutModal} isShow={timeoutModalProps.isShow} />
    </MainWrapper>
  )
}

export default MainPage

MainPage.propTypes = {
  history: PropTypes.shape({}),
  notificationBarProps: PropTypes.shape({
    isActive: PropTypes.bool,
    text: PropTypes.string,
    type: PropTypes.string,
    duration: PropTypes.number,
    path: PropTypes.string,
    onButtonClick: PropTypes.func,
    buttonText: PropTypes.string,
    modalElement: PropTypes.string
  }),
  timeoutModalProps: PropTypes.shape({
    isShow: PropTypes.bool
  }),
  setNotificationBar: PropTypes.func,
  setTimeoutModal: PropTypes.func,
  logout: PropTypes.func,
  userInfo: PropTypes.shape({
    userInfo: PropTypes.shape({
      user_group: PropTypes.string
    })
  })
}
