import styled from 'styled-components'
import { setFlex, setColor } from '../../globalStyled'

export const AppWrapper = styled.div`
  height: 100vh;
  display: flex;
  align-content: stretch;
  flex-direction: column;
  color: var(--color-white);

  .body {
    background-color: #ebebeb;
    align-self: stretch;
    height: fit-content;
    display: flex;
    flex-direction: row;
    height: 100vh;
    &__right {
      width: 100%;
      overflow: auto;
      background: var(--color-grey-light);
      &::-webkit-scrollbar {
        width: 16px;
        &-track {
          margin-top: 3px;
        }
        &-thumb {
          background-color: var(--cl-blue500);
          border-radius: 40px;
          border: 4px solid var(--color-grey-light);
        }
      }
    }
  }
`
export const LoadingWrapper = styled.div`
  height: 100vh;
  width: 100vw;
  ${setFlex()};
  .loader {
    width: 25rem;
    height: 5rem;
    line-height: 5rem;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-family: helvetica, arial, sans-serif;
    text-transform: uppercase;
    font-weight: 900;
    color: ${setColor.emerald};
    letter-spacing: 0.2em;

    &::before,
    &::after {
      content: '';
      display: block;
      width: 1.5rem;
      height: 1.5rem;
      background: ${setColor.emerald};
      position: absolute;
      animation: load 0.7s infinite alternate ease-in-out;
    }

    &::before {
      top: 0;
    }

    &::after {
      bottom: 0;
    }
  }

  @keyframes load {
    0% {
      left: 0;
      height: 3rem;
      width: 1.5rem;
    }
    50% {
      height: 0.8rem;
      width: 4rem;
    }
    100% {
      left: 23.5rem;
      height: 3rem;
      width: 1.5rem;
    }
  }
`
