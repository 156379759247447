import { createAsyncThunk } from '@reduxjs/toolkit'
import request, {
  GETOption,
  PUTOption
} from '../../utils/requestAxios'
import { BROADCAST_API, ENDPOINT_KEY, SEGMENT_API } from '../../utils/api'
import { getBroadcastList } from '../BroadcastListPage/action'
import {
  resetEditBroadcastDataState,
  setEditBroadcastDataState
} from './editBroadcastSlice'
import { _formatBodyForBroadcastAPI } from '../../utils/broadcastActions'

const _formatTextMessage = (message, type) => {
  let text = ''
  if(type === 'text') {
    text = message?.message || ''
  }
  return text
}

const _formatNotiMessage = (notiMessage, type) => {
  let message = ''
  if(type === 'image' || type === 'video' || type === 'coupon') {
    message = notiMessage?.notification_message || ''
  }
  return message
}

const _getTargetReceiver = (segment) => {
  let target = 'all'
  if(segment && segment.length > 0) {
    target = 'group'
  } else {
    target = 'all'
  }
  return target
}

const _formatToEditBroadcastData = (broadcast) => {
  const message = broadcast.messages[0]
  const result = {
    targetReceiver: _getTargetReceiver(broadcast.segment),
    broadcastTimeType: 'schedule',
    selectedDate: new Date(broadcast.broadcast_at),
    message: _formatTextMessage(message, broadcast.type),
    media: message?.image && message?.image.image_url,
    mediaUrl: message?.image && message?.image.image_url,
    embedUrl: message?.data?.link || '',
    notiMessage: _formatNotiMessage(message, broadcast.type),
    targetQuantity: broadcast?.target_quantity,
    broadcastType: broadcast.type,
    selectedSegment: broadcast?.segment || [],
    status: 'getDataSuccessfully',
    broadcastStatus: broadcast?.status,
    thumbnail: {
      width: message?.thumbnail?.width, height: message?.thumbnail?.height, url: message?.thumbnail?.thumbnail_url
    },
    mediaDimensions: { width: message?.thumbnail?.width, height: message?.thumbnail?.height },
    promoCode: message?.data?.promotion_code || '',
    openRate: {
      counts: broadcast?.open_counts,
      date: broadcast?.open_counts_date,
      percentage: broadcast?.open_rate,
      isTracking: broadcast?.is_tracking_open_counts
    }
  }
  return result
}

export const getBroadcastById = createAsyncThunk(
  'broadcast/getBroadcastById',
  async (payload, thunkAPI) => {
    const { broadcastId } = payload
    const { broadcast } = await request(
      `${BROADCAST_API}/${broadcastId}?${ENDPOINT_KEY}`,
      GETOption()
    )
    const formatBroadcast = _formatToEditBroadcastData(broadcast)
    await thunkAPI.dispatch(setEditBroadcastDataState(formatBroadcast))
    return broadcast
  }
)

export const editBroadcast = createAsyncThunk(
  'broadcast/editBroadcast',
  async (payload, thunkAPI) => {
    const body = await _formatBodyForBroadcastAPI(payload, 'edit')
    const response = await request(
        `${BROADCAST_API}?${ENDPOINT_KEY}`,
        PUTOption(body)
      )
    await thunkAPI.dispatch(getBroadcastList())
    await thunkAPI.dispatch(resetEditBroadcastDataState())

    return response
  }
)

export const getSegmentList = createAsyncThunk(
  'broadcast/getSegmentList',
  async () => {
    const response = await request(`${SEGMENT_API}`, GETOption())
    return response
  }
)
