import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  NotificationBarWrapper,
  customButtonStyle,
  LearnMoreModal
} from './styled'
import { setColor } from '../../globalStyled'
import errorIcon from '../../imgs/error_white.svg'
import warnIcon from '../../imgs/caution_white.svg'
import successIcon from '../../imgs/success_white.svg'
import infoIcon from '../../imgs/info_white.svg'
import { generateTestId } from '../../utils/tools'
import { OverlayCustomModal } from '../CustomModal'
import { TextButton, PrimaryButton } from '../Button'
import profanityGif from '../../imgs/profanity.gif'

const NotificationBar = ({
  text,
  type = 'warn',
  isActive,
  buttonText,
  modalElement
}) => {
  let stateItem

  const [isShowModal, setIsShowModal] = useState(false)
  switch (type) {
    case 'warn':
      stateItem = { backgroundColor: setColor.yellow, icon: warnIcon }
      break
    case 'error':
      stateItem = { backgroundColor: setColor.cherryRed, icon: errorIcon }
      break
    case 'info':
      stateItem = { backgroundColor: setColor.cerulean, icon: infoIcon }
      break
    default:
      stateItem = { backgroundColor: setColor.jungleGreen, icon: successIcon }
      break
  }

  const _getGif = () => {
    const randomNumber = Math.random()
    // NOTE to make .gif start from the begining when open modal
    return `${profanityGif}?a=${randomNumber}`
  }

  const _renderModal = (modal) => {
    if(modal === 'profanity') {
      const infoHeader = 'คำไม่เหมาะสม'
      const infoMessage1 =
        'คำไม่เหมาะสมที่บันทึกในระบบ จะไม่แสดงบนหน้าจอของผู้ใช้งานขณะไลฟ์ตรีม'
      const infoMessage2 =
        'กรณีผู้ใช้งานส่งข้อความที่ไม่เหมาะสม เข้าไลฟ์สตรีมจะถูกปฏิเสธ เนื่องจากมีคำไม่เหมาะสมตามรายการที่บันทึกในระบบ'
      const infoExample =
        'ข้อความ"กูลิโกะ" จะถูกปฏิเสธ เนื่องจากคำว่า "กู" ถูกบันทึกในระบบ'
      return (
        <OverlayCustomModal
          isShow={isShowModal}
          width='81.3rem'
          height='49.1rem'
          customStyle={{ padding: 0 }}
          id={generateTestId('profanityModal', 'wrapper', 'modal')}
        >
          <LearnMoreModal>
            <div className='gif__wrapper'>
              <img
                src={_getGif()}
                className='gif'
                alt=''
                data-testid='profanity-modal-image'
                id={generateTestId('profanityModal', 'img', 'gif')}
              />
            </div>
            <div className='text__container'>
              <div className='text__wrapper'>
                <div
                  className='text__header'
                  data-testid='profanity-modal-header'
                  id={generateTestId('profanityModal', 'header', 'infoHeader')}
                >
                  {infoHeader}
                </div>
                <ul>
                  <li
                    className='text__list'
                    data-testid='profanity-modal-message-1'
                    id={generateTestId('profanityModal', 'text', 'infoMessage1')}
                  >
                    {infoMessage1}
                  </li>
                  <li
                    className='text__list'
                    data-testid='profanity-modal-message-2'
                    id={generateTestId('profanityModal', 'text', 'infoMessage2')}
                  >
                    {infoMessage2}
                  </li>
                </ul>
                <div
                  className='text__example first'
                  data-testid='profanity-modal-example'
                  id={generateTestId('profanityModal', 'text', 'example')}
                >
                  ตัวอย่าง:
                </div>
                <div
                  className='text__example'
                  data-testid='profanity-modal-example-text'
                  id={generateTestId('profanityModal', 'text', 'infoExample')}
                >
                  {infoExample}
                </div>
              </div>
              <PrimaryButton
                onClick={() => {
                  setIsShowModal(false)
                }}
                testid='profanity-modal-close-button'
                id={generateTestId('profanityModal', 'button', 'close')}
                customStyle={{ height: '3.6rem', width: '10.5rem' }}
              >
                ปิด
              </PrimaryButton>
            </div>
          </LearnMoreModal>
        </OverlayCustomModal>
      )
    }
  }
  return (
    <NotificationBarWrapper
      backgroundColor={stateItem.backgroundColor}
      data-testid='notification-bar'
      type={type}
      isShow={isActive}
      id={generateTestId('notificationBar', 'wrapper', 'bar')}
    >
      <div className='text'>
        <img
          src={stateItem.icon}
          alt=''
          id={generateTestId('notificationBar', 'img', 'icon')}
          className='icon'
        />
        <p
          data-testid='notificationBar-text'
          id={generateTestId('notificationBar', 'text', 'message')}
        >
          {text}
        </p>
      </div>
      <div className='btn__wrapper'>
        {buttonText && (
          <TextButton
            customStyle={customButtonStyle}
            onClick={() => setIsShowModal(true)}
            testid='extra-button'
            id={generateTestId('notificationBar', 'button', 'buttonText')}
          >
            {buttonText}
          </TextButton>
        )}
      </div>
      {_renderModal(modalElement)}
    </NotificationBarWrapper>
  )
}

export default NotificationBar

NotificationBar.propTypes = {
  text: PropTypes.string,
  type: PropTypes.string,
  isActive: PropTypes.bool,
  buttonText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  modalElement: PropTypes.string
}
