export function getToken() {
  return localStorage.getItem('jwtToken')
}

export function getStatus() {
  return localStorage.getItem('isLoggedIn')
}

export function clearToken() {
  localStorage.setItem('jwtToken', '')
}

export function setToken(credential) {
  localStorage.setItem('jwtToken', credential)
}

export function setStatus(status) {
  localStorage.setItem('isLoggedIn', status)
}

export default {
  getToken,
  clearToken,
  setToken
}
