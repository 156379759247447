import { createSlice } from '@reduxjs/toolkit'
import { getProfanityItem, updateProfanityItem } from './action'

export const profanitySlice = createSlice({
  name: 'profanity',
  initialState: {
    profanityItem: {
      status: 'idle',
      data: []
    },
    updateProfanityResponse: {
      status: 'idle'
    },
    profanityState: []
  },
  reducers: {
    resetProfanityItemStatus(state) {
      state.profanityItem.status = 'idle'
      state.profanityItem.data = []
    },
    clearUpdateResponse(state) {
      state.updateProfanityResponse.status = 'idle'
    },
    setProfanityState(state, action) {
      state.profanityState = action.payload
    }
  },
  extraReducers: {
    [getProfanityItem.pending]: (state) => {
      state.profanityItem.status = 'loading'
    },
    [getProfanityItem.fulfilled]: (state, action) => {
      state.profanityItem.status = 'succeeded'
      state.profanityItem.data = action.payload
      state.profanityState = action.payload
    },
    [getProfanityItem.rejected]: (state) => {
      state.profanityItem.status = 'failed'
      state.profanityItem.data = []
      state.profanityState = []
    },
    [updateProfanityItem.pending]: (state) => {
      state.updateProfanityResponse.status = 'loading'
    },
    [updateProfanityItem.fulfilled]: (state, action) => {
      state.updateProfanityResponse.status = 'succeeded'
      state.profanityItem.data = action.payload
    },
    [updateProfanityItem.rejected]: (state) => {
      state.updateProfanityResponse.status = 'failed'
    }
  }
})

export const selectProfanityItem = (state) => state.profanity.profanityItem
export const selectProfanityState = (state) => state.profanity.profanityState
export const selectProfanityUpdateResponse = (state) =>
  state.profanity.updateProfanityResponse

export const {
  resetProfanityItemStatus,
  clearUpdateResponse,
  setProfanityState
} = profanitySlice.actions

export default profanitySlice.reducer
