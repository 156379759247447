import styled, { css } from 'styled-components'
import {
  setColor,
  setFlex,
  setTransition,
  buttonStyle
} from '../../globalStyled'

export const ButtonWrapper = styled.button`
  height: 48px;
  width: ${(props) => (props.block ? '100%' : 'unset')};
  background: ${(props) => {
    if(props.disabled) {
      return '#cccccc'
    }
    return props.background ? props.background : `${setColor.primaryGreen}`
  }};
  color: ${(props) => (props.color ? props.color : `${setColor.grayWhite}`)};
  padding: 10px 16px;
  border: none;
`

const commonButtonStyle = css`
  ${buttonStyle};
  padding: 1rem 1.85rem;
  border-radius: 4px;
  height: 3.6rem;
  ${setFlex()};
  ${setTransition('0.1s')};
  &:hover {
    transform: scale(1.02);
  }
  &:active {
    transform: scale(0.98);
  }
`

export const PrimaryButtonWrapper = styled.button`
  ${commonButtonStyle};
  color: ${setColor.white};
  background-color: ${setColor.primaryGreen};
  min-width: 9.8rem;
  padding: 1rem 0;
  font-size: 1.4rem;
  ${(props) => css`
    ${props.customStyle}
  `};
  &:disabled {
    cursor: not-allowed;
    color: ${setColor.grayText};
    background-color: ${(props) => (props.isLoading ? setColor.primaryGreen : setColor.grayWhite)};
    &:hover {
      transform: none;
    }
  }
`

export const FlatButtonWrapper = styled.button`
  ${commonButtonStyle};
  color: ${setColor.brownishGrey};
  border: 1px solid ${setColor.primaryGreen};
  background-color: ${setColor.transparent};
  font-size: 1.4rem;
  ${(props) => css`
    ${props.customStyle}
  `};
  &:disabled {
    cursor: not-allowed;
    color: ${setColor.grayText};
    background-color: ${setColor.grayWhite};
    border: none;
    &:hover {
      transform: none;
    }
  }
`
export const TextButtonWrapper = styled.button`
  ${commonButtonStyle};
  color: ${setColor.brownishGrey};
  background-color: ${setColor.transparent};
  font-size: 1.4rem;
  ${(props) => css`
    ${props.customStyle}
  `};
  &:disabled {
    cursor: not-allowed;
    color: ${setColor.veryLightPink};
    &:hover {
      transform: none;
    }
  }
`

export const BackButtonWrapper = styled.button`
  ${commonButtonStyle};
  hight: fit-content;
  width: fit-content;
  text-transform: uppercase;
  font-size: 1.2rem;
  line-height: 1.33;
  color: ${setColor.emerald};
  padding: 0;
  ${setFlex()};
  margin-bottom: 1rem;
  &:hover {
    transform: none;
  }
  &:disabled {
    cursor: not-allowed;
  }
`

export const InfoWrapper = styled.button`
  ${commonButtonStyle};
  padding: 0;
  img {
    width: 2.4rem;
    height: 2.4rem;
  }
`
