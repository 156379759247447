import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Prompt } from 'react-router-dom'
import { OverlayCustomModal } from '../CustomModal'
import infoIcon from '../../imgs/error.svg'
import { generateTestId } from '../../utils/tools'

const RouteLeavingGuard = ({
  when,
  navigate,
  shouldBlockNavigation,
  onConfirm,
  onCancel,
  title = '',
  description = '',
  confirmButton = '',
  cancelButton = '',
  history
}) => {
  const [modalVisible, setModalVisible] = useState(false)
  const [lastLocation, setLastLocation] = useState(null)
  const [confirmedNavigation, setConfirmedNavigation] = useState(false)

  const handleBlockedNavigation = (nextLocation) => {
    if(!confirmedNavigation && shouldBlockNavigation) {
      setModalVisible(true)
      setLastLocation({
        ...nextLocation,
        state: { past: history.location.pathname, isBlocked: false }
      })
      return false
    }
    return true
  }

  const handleConfirmNavigationClick = () => {
    onConfirm(lastLocation)
    setConfirmedNavigation(true)
    setModalVisible(false)
  }
  const handelCancelNavigationClick = () => {
    if(onCancel) {
      onCancel(lastLocation)
      setConfirmedNavigation(false)
    }
    setModalVisible(false)
  }

  useEffect(() => {
    const _shouldNavigate = () => {
      if(confirmedNavigation && lastLocation) {
        navigate(lastLocation)
      }
    }
    _shouldNavigate()
    return () => {
      _shouldNavigate()
    }
  }, [confirmedNavigation, lastLocation, navigate])

  const _checkIsRefreshPerformed = () =>
    window.performance.navigation.type === 1

  useEffect(() => {
    window.onbeforeunload = (e) => {
      if(window.performance && shouldBlockNavigation) {
        return _checkIsRefreshPerformed()
      }
      e.preventDefault()
    }
  }, [shouldBlockNavigation])
  return (
    <>
      <Prompt when={when} message={handleBlockedNavigation} />
      <OverlayCustomModal
        title={title}
        description={description}
        onConfirm={handleConfirmNavigationClick}
        onCancel={handelCancelNavigationClick}
        confirmButton={confirmButton}
        cancelButton={cancelButton}
        icon={infoIcon}
        isShow={modalVisible}
        id={generateTestId('RouteLeavingGuard', 'modal', title)}
      />
    </>
  )
}

export default RouteLeavingGuard

RouteLeavingGuard.propTypes = {
  when: PropTypes.bool,
  navigate: PropTypes.func,
  shouldBlockNavigation: PropTypes.bool,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  title: PropTypes.string,
  description: PropTypes.string,
  confirmButton: PropTypes.string,
  cancelButton: PropTypes.string,
  history: PropTypes.shape({
    location: PropTypes.shape({
      pathname: PropTypes.string
    })
  })
}
