import { createSlice } from '@reduxjs/toolkit'
import { getUserInfo } from './actions'

export const appSlice = createSlice({
  name: 'app',
  initialState: {
    user: {
      status: 'idle',
      userInfo: {}
    }
  },
  reducers: {
    setUserProfile: (state, action) => {
      state.user = action.payload
    }
  },
  extraReducers: {
    [getUserInfo.pending]: (state) => {
      state.user.status = 'loading'
    },
    [getUserInfo.fulfilled]: (state, action) => {
      state.user.status = 'succeeded'
      state.user.userInfo = action.payload
    },
    [getUserInfo.rejected]: (state) => {
      state.user.status = 'failed'
    }
  }
})

export const { setUserProfile } = appSlice.actions

export const selectUserProfile = (state) => state.app.user

export default appSlice.reducer
