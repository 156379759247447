/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { GreetingEditorWrapper } from './styled'
import { FlatButton } from '../Button'
import MessageSection from './MessageSection'
import SmartReplySection from './SmartReplySection'

import addIconGreen from '../../imgs/add_green.svg'
import { generateTestId } from '../../utils/tools'
import HeaderAction from '../HeaderAction'
import Skeleton from '../Skeleton'

const GreetingMessageEditor = ({
  greetingItem,
  message,
  setMessage,
  smartReply,
  setSmartReply,
  headerAction,
  onSaveClicked,
  updateGreetingResponse,
  isLoading
}) => {
  const smartReplyInputRef = useRef(null)
  useEffect(() => {
    if(
      greetingItem.status !== 'failed' &&
      smartReply.length > 0 &&
      smartReplyInputRef.current &&
      smartReply !== greetingItem.data.smartReply
    ) {
      smartReplyInputRef.current.focus()
    }
  }, [smartReply.length])
  const addSmartReplyField = () => {
    if(smartReply.length < 4) {
      const newSmartReplyList = [...smartReply, { text: '' }]
      setSmartReply(newSmartReplyList)
    }
  }

  const removeSmartReply = (index) => {
    const newSmartReplyList = [...smartReply]
    newSmartReplyList.splice(index, 1)
    setSmartReply(newSmartReplyList)
  }

  const _checkIsStartsWithSpace = (val) => {
    const startsWithSpace = /^\s/.test(val)
    return startsWithSpace ? val.replace(/\s/, '') : val
  }

  const handleOnSmartReplyChange = (e, index) => {
    e.preventDefault()
    const tempSmartReply = [...smartReply]
    tempSmartReply[index] = { text: _checkIsStartsWithSpace(e.target.value) }
    setSmartReply(tempSmartReply)
  }
  const handleEnterKeyAdd = (keycode) => keycode === 13 && addSmartReplyField()
  const removeAllSmartReply = () => setSmartReply([])

  const _toggleSmartReplySection = () => {
    let section
    if(isLoading) {
      section = <Skeleton height='30.4rem' customStyle='margin-top: 1.2rem;' />
    } else if(smartReply.length === 0) {
      section = (
        <FlatButton
          onClick={() => addSmartReplyField()}
          customStyle='margin-top:1.2rem;'
          testid='smartReply-add-button'
          disabled={greetingItem.status !== 'succeeded'}
          id={generateTestId('greetingMessageEditor', 'button', 'addSmartReply')}
        >
          <>
            <img
              src={addIconGreen}
              alt=''
              id={generateTestId('greetingMessageEditor', 'img', 'addSmartReply')}
            />
            เพิ่มสมาร์ทรีพลาย
          </>
        </FlatButton>
      )
    } else {
      section = (
        <SmartReplySection
          setSmartReply={setSmartReply}
          smartReply={smartReply}
          greetingItem={greetingItem}
          smartReplyInputRef={smartReplyInputRef}
          handleOnSmartReplyChange={handleOnSmartReplyChange}
          handleEnterKeyAdd={handleEnterKeyAdd}
          removeSmartReply={removeSmartReply}
          removeAllSmartReply={removeAllSmartReply}
          addSmartReplyField={addSmartReplyField}
          isLoading={isLoading}
        />
      )
    }
    return section
  }

  return (
    <GreetingEditorWrapper>
      <HeaderAction
        hideUpdateSection
        time={headerAction?.time}
        author={headerAction?.author}
        onPrimaryClick={() => onSaveClicked()}
        primaryResponse={updateGreetingResponse}
        primaryButton='บันทึก'
        isLoading={isLoading}
      />
      <div className='wrapper'>
        <MessageSection
          setMessageState={setMessage}
          messageState={message}
          greetingItem={greetingItem}
          isLoading={isLoading}
        />
        {_toggleSmartReplySection()}
      </div>
    </GreetingEditorWrapper>
  )
}

export default GreetingMessageEditor

GreetingMessageEditor.propTypes = {
  headerAction: PropTypes.exact({
    time: PropTypes.string,
    author: PropTypes.string
  }),
  greetingItem: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.object, PropTypes.string])),
  updateGreetingResponse: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.object, PropTypes.string])),
  onSaveClicked: PropTypes.func,
  setSmartReply: PropTypes.func,
  setMessage: PropTypes.func,
  message: PropTypes.string,
  smartReply: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.object])),
  isLoading: PropTypes.bool
}
