import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { ExpandCollapseGroupWrapper, List, ListWrapper } from './styled'
import { generateTestId } from '../../utils/tools'

const ExpandCollapseGroup = ({
  label = '',
  list = [{ name: '', id: '' }],
  setIsActive = () => {},
  isActive = false,
  width = '32rem',
  height,
  customStyle,
  maxHeight,
  minHeight,
  id,
  customStyleList
}) => {
  const [isShow, setIsShow] = useState(isActive)
  const toggleActive = () => {
    setIsShow(!isShow)
    setIsActive(!isShow)
  }
  const _generateList = () =>
    list.map((list) => (
      <List
        key={list.id}
        data-testid={`expandCollapse-list-${list.name}`}
        customStyle={customStyleList}
        id={generateTestId('ExpandCollapseGroup', 'text', list.name)}
        width={width}
      >
        {list.name}
      </List>
    ))

  return (
    <ExpandCollapseGroupWrapper
      isActive={isShow}
      width={width}
      height={height}
      minHeight={minHeight}
      maxHeight={maxHeight}
      customStyle={customStyle}
      id={id || generateTestId('ExpandCollapseGroupWrapper', 'wrapper', label)}
    >
      <button
        type='button'
        className='label'
        onClick={() => toggleActive()}
        data-testid='expandCollapse-dropdown-button'
        id={generateTestId('ExpandCollapseGroup', 'button', label)}
      >
        {label}
        <span
          className='label__arrow'
          id={generateTestId('ExpandCollapseGroup', 'img', 'arrow')}
        />
      </button>
      <ListWrapper
        isActive={isShow}
        customStyle={customStyle}
        id={generateTestId('ExpandCollapseGroup', 'wrapper', 'list')}
        width={width}
      >
        {isShow && _generateList()}
      </ListWrapper>
    </ExpandCollapseGroupWrapper>
  )
}

ExpandCollapseGroup.propTypes = {
  label: PropTypes.string,
  list: PropTypes.arrayOf(PropTypes.object),
  setIsActive: PropTypes.func,
  isActive: PropTypes.bool,
  width: PropTypes.string,
  id: PropTypes.string,
  height: PropTypes.string,
  customStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  customStyleList: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  maxHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  minHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
}

export default ExpandCollapseGroup
