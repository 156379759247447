import { createSlice } from '@reduxjs/toolkit'
import {
  uploadSegment
} from './action'

export const segmentSlice = createSlice({
  name: 'segment',
  initialState: {
    uploadSegmentState: {
      status: 'idle',
      response: {},
      error: undefined
    },
    segmentList: {

    }
  },
  reducers: {
    resetUploadSegmentStatus(state) {
      state.uploadSegmentState.status = 'idle'
      state.uploadSegmentState.response = {}
    },
    setUploadSegmentPendingStatus(state) {
      state.uploadSegmentState.status = 'pending'
    }
  },
  extraReducers: {
    [uploadSegment.fulfilled]: (state, action) => {
      state.uploadSegmentState.status = 'succeeded'
      state.uploadSegmentState.response = action.payload
    },
    [uploadSegment.rejected]: (state, action) => {
      state.uploadSegmentState.status = 'failed'
      state.uploadSegmentState.error = action.error.message
    }
  }
})

export const {
  resetUploadSegmentStatus,
  setUploadSegmentPendingStatus
} = segmentSlice.actions

export const selectUploadSegment = (state) => state.segment.uploadSegmentState

export default segmentSlice.reducer
