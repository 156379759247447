import { createSlice } from '@reduxjs/toolkit'
import {
  createBroadcast,
  saveDraftBroadcast,
  getSegmentList
} from './action'
import { _roundingMinute } from '../../utils/tools'

const initBroadcastState = (date = new Date()) => ({
  targetReceiver: 'all',
  broadcastTimeType: 'now',
  selectedDate: _roundingMinute(new Date(date)),
  message: '',
  media: null,
  mediaUrl: '',
  embedUrl: '',
  notiMessage: '',
  targetQuantity: null,
  broadcastType: 'text',
  selectedSegment: [],
  status: 'initial',
  broadcastStatus: '',
  thumbnail: {
    width: null, height: null, url: null
  },
  mediaDimensions: { width: null, height: null },
  promoCode: ''
})

export const createBroadcastSlice = createSlice({
  name: 'createBroadcast',
  initialState: {
    createBroadcastResponse: {
      status: 'idle',
      response: {}
    },
    saveDraftBroadcastResponse: {
      status: 'idle',
      response: {}
    },
    createBroadcastDataState: initBroadcastState(),
    segmentList: {
      status: 'idle',
      data: []
    }
  },
  reducers: {
    resetCreateBroadcastResponse(state) {
      state.createBroadcastResponse.status = 'idle'
      state.createBroadcastResponse.response = {}
    },
    resetSaveDraftBroadcastResponse(state) {
      state.saveDraftBroadcastResponse.status = 'idle'
      state.saveDraftBroadcastResponse.response = {}
    },
    setCreateBroadcastDataState(state, { payload }) {
      state.createBroadcastDataState = payload
    },
    resetCreateBroadcastDataState(state) {
      state.createBroadcastDataState = initBroadcastState()
    },
    resetSegmentList(state) {
      state.segmentList.status = 'idle'
      state.segmentList.data = []
    }
  },
  extraReducers: {
    [createBroadcast.pending]: (state) => {
      state.createBroadcastResponse.status = 'loading'
    },
    [createBroadcast.fulfilled]: (state, action) => {
      state.createBroadcastResponse.status = 'succeeded'
      state.createBroadcastResponse.response = action.payload
      state.broadcastType = 'text'
    },
    [createBroadcast.rejected]: (state) => {
      state.createBroadcastResponse.status = 'failed'
    },
    [getSegmentList.pending]: (state) => {
      state.segmentList.status = 'loading'
    },
    [getSegmentList.fulfilled]: (state, action) => {
      state.segmentList.status = 'succeeded'
      state.segmentList.data = action.payload
    },
    [getSegmentList.rejected]: (state) => {
      state.segmentList.status = 'failed'
      state.segmentList.response = []
    },

    [saveDraftBroadcast.pending]: (state) => {
      state.saveDraftBroadcastResponse.status = 'loading'
    },
    [saveDraftBroadcast.fulfilled]: (state, action) => {
      state.saveDraftBroadcastResponse.status = 'succeeded'
      state.saveDraftBroadcastResponse.response = action.payload
    },
    [saveDraftBroadcast.rejected]: (state, action) => {
      state.saveDraftBroadcastResponse.status = 'failed'
      state.saveDraftBroadcastResponse.response = action.payload
    }
  }
})

export const {
  resetCreateBroadcastResponse,
  resetSaveDraftBroadcastResponse,
  setCreateBroadcastDataState,
  resetCreateBroadcastDataState,
  resetSegmentList
} = createBroadcastSlice.actions

export const selectCreateBroadcastResponse = (state) =>
  state.createBroadcast.createBroadcastResponse
export const selectSaveDraftBroadcastResponse = (state) =>
  state.createBroadcast.saveDraftBroadcastResponse
export const selectBroadcastDataState = (state) =>
  state.createBroadcast.createBroadcastDataState
export const selectSegmentList = (state) => state.createBroadcast.segmentList

export default createBroadcastSlice.reducer
