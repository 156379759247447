import React from 'react'
import PropTypes from 'prop-types'
import { generateTestId } from '../../utils/tools'
import { SkeletonWrapper } from './styled'

const Skeleton = ({ width = '100%', height = '100%', customStyle }) => (
  <SkeletonWrapper
    width={width}
    height={height}
    customStyle={customStyle}
    id={generateTestId('skeleton', 'bar')}
    data-testid='skeleton'
  />
)

export default Skeleton

Skeleton.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  customStyle: PropTypes.string
}
