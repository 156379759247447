import styled from 'styled-components'
import { setFlex, borderStyle } from '../../globalStyled'

export const HeaderActionWrapper = styled.div`
  ${setFlex('space-between')};
  padding: 2rem 2.4rem;
  border-bottom: ${borderStyle};
  height: 7.6rem;
  .button {
    &-wrapper {
      ${setFlex('flex-end')};
      flex-direction: row-reverse;
    }
  }
`
