/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import GreetingMessagePage from '.'
import { selectUserProfile } from '../App/appSlice'
import {
  selectGreetingItem,
  selectUpdateGreetingResponse,
  clearState,
  resetGreetingItemStatus
} from './greetingSlice'
import { getGreetingItem, updateGreetingItem } from './action'
import { setNotificationBar } from '../MainPage/MainSlice'
import SnackBar from '../../components/Snackbar'
import { GET_DATA_FAILED_MESG_NOTI, GET_DATA_FAILED_MESG_SNACK } from '../../utils/errorMessages'

const GreetingMessageConnector = ({ history }) => {
  const dispatch = useDispatch()
  const greetingItem = useSelector(selectGreetingItem)
  const updateGreetingResponse = useSelector(selectUpdateGreetingResponse)
  const userProfile = useSelector(selectUserProfile)

  const [snack, setSnack] = useState({
    isActive: undefined,
    text: undefined,
    duration: undefined,
    type: undefined,
    showAt: undefined
  })

  useEffect(() => {
    if(greetingItem.status === 'idle' &&
      userProfile.status !== 'failed') {
      dispatch(getGreetingItem())
    }
  }, [])

  useEffect(() => {
    const { status } = updateGreetingResponse
    const _handleUpdateGreeting = () => {
      if(status === 'succeeded') {
        setSnack({
          isActive: true,
          text: 'บันทึกเรียบร้อยแล้ว',
          type: 'success',
          showAt: '/greeting'
        })
      } else if(status === 'failed') {
        setSnack({
          isActive: true,
          text: GET_DATA_FAILED_MESG_SNACK,
          type: 'error',
          showAt: '/greeting'
        })
      }
    }
    _handleUpdateGreeting()
    return () => {
      if(status !== 'idle') {
        dispatch(clearState())
      }
    }
  }, [updateGreetingResponse])

  useEffect(() => {
    const { status } = greetingItem
    if(status === 'failed') {
      dispatch(
        setNotificationBar({
          isActive: true,
          type: 'warn',
          text: GET_DATA_FAILED_MESG_NOTI,
          path: '/greeting'
        })
      )
    }
    if(status === 'succeeded') {
      dispatch(
        setNotificationBar({
          isActive: false,
          path: '/greeting'
        })
      )
    }
  }, [greetingItem])

  return (
    <>
      <GreetingMessagePage
        isLoading={
          updateGreetingResponse.status === 'loading' ||
          greetingItem.status === 'loading'
        }
        history={history}
        greetingItem={greetingItem}
        updateGreetingResponse={updateGreetingResponse}
        updateGreetingItemRequest={(message, smartReply) =>
          dispatch(updateGreetingItem(message, smartReply))}
        resetGreetingItemStatus={() => dispatch(resetGreetingItemStatus())}
      />
      <SnackBar
        isActive={snack.isActive}
        text={snack.text}
        duration={snack.duration}
        type={snack.type}
        showAt={snack.showAt}
        setSnackBar={setSnack}
        history={history}
      />
    </>
  )
}

export default GreetingMessageConnector
GreetingMessageConnector.propTypes = {
  history: PropTypes.shape({})
}
