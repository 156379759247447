import { createSlice } from '@reduxjs/toolkit'
// eslint-disable-next-line import/no-cycle
import {
  getBroadcastById,
  editBroadcast,
  getSegmentList
} from './action'
import { _roundingMinute } from '../../utils/tools'

const initBroadcastState = (date = new Date()) => ({
  targetReceiver: 'all',
  broadcastTimeType: 'now',
  selectedDate: _roundingMinute(new Date(date)),
  message: '',
  media: null,
  embedUrl: '',
  mediaUrl: '',
  notiMessage: '',
  targetQuantity: null,
  broadcastType: 'text',
  selectedSegment: [],
  status: 'initial',
  broadcastStatus: '',
  thumbnail: {
    width: null, height: null, url: null
  },
  mediaDimensions: { width: null, height: null },
  promoCode: '',
  openRate: {
    counts: null,
    date: null,
    percentage: null,
    isTracking: null
  }
})

export const editBroadcastSlice = createSlice({
  name: 'editBroadcast',
  initialState: {
    editBroadcastResponse: {
      status: 'idle',
      response: {}
    },
    getBroadcastResponse: {
      status: 'idle',
      response: {}
    },
    saveDraftBroadcastResponse: {
      status: 'idle',
      response: {}
    },
    editBroadcastDataState: initBroadcastState(),
    segmentList: {
      status: 'idle',
      data: []
    }
  },
  reducers: {
    resetEditBroadcastResponse(state) {
      state.editBroadcastResponse.status = 'idle'
      state.editBroadcastResponse.response = {}
    },
    resetGetBroadcastResponse(state) {
      state.getBroadcastResponse.status = 'idle'
      state.getBroadcastResponse.response = {}
    },
    setEditBroadcastDataState(state, { payload }) {
      state.editBroadcastDataState = payload
    },
    resetEditBroadcastDataState(state) {
      state.editBroadcastDataState = initBroadcastState()
    }
  },
  extraReducers: {
    [getBroadcastById.pending]: (state) => {
      state.getBroadcastResponse.status = 'loading'
    },
    [getBroadcastById.fulfilled]: (state, action) => {
      state.getBroadcastResponse.status = 'succeeded'
      state.getBroadcastResponse.response = action.payload
    },
    [getBroadcastById.rejected]: (state) => {
      state.getBroadcastResponse.status = 'failed'
    },
    [editBroadcast.pending]: (state) => {
      state.editBroadcastResponse.status = 'loading'
    },
    [editBroadcast.fulfilled]: (state, action) => {
      state.editBroadcastResponse.status = 'succeeded'
      state.editBroadcastResponse.response = action.payload
      state.broadcastType = 'text'
    },
    [editBroadcast.rejected]: (state) => {
      state.editBroadcastResponse.status = 'failed'
    },
    [getSegmentList.pending]: (state) => {
      state.segmentList.status = 'loading'
    },
    [getSegmentList.fulfilled]: (state, action) => {
      state.segmentList.status = 'succeeded'
      state.segmentList.data = action.payload
    },
    [getSegmentList.rejected]: (state) => {
      state.segmentList.status = 'failed'
      state.segmentList.response = []
    }
  }
})

export const {
  resetEditBroadcastResponse,
  resetGetBroadcastResponse,
  setEditBroadcastDataState,
  resetEditBroadcastDataState
} = editBroadcastSlice.actions

export const selectEditBroadcastResponse = (state) =>
  state.editBroadcast.editBroadcastResponse
export const selectGetBroadcastResponse = (state) =>
  state.editBroadcast.getBroadcastResponse
export const selectBroadcastDataState = (state) =>
  state.editBroadcast.editBroadcastDataState
export const selectSegmentList = (state) => state.editBroadcast.segmentList

export default editBroadcastSlice.reducer
