import styled, { css } from 'styled-components'
import {
  boxShadow,
  setColor,
  buttonStyle,
  setFlex,
  setTransition
} from '../../globalStyled'

export const NotificationBarWrapper = styled.div`
  width: 100vw;
  background-color: ${(props) => props.backgroundColor};
  ${setFlex('space-between')};
  color: ${setColor.white};
  height: 4rem;
  transform: scaleY(${(props) => (props.isShow ? '1' : '0')});
  transform-origin: top;
  padding: 0 2.5rem;
  ${setTransition()}
  ${boxShadow};
  .text {
    ${setFlex()};
    p {
      font-size: 14px;
      margin: auto;
      margin-left: 1.5rem;
    }
    .icon{
      width: 2rem;
      height: 2rem;
    }
  }
  .btn {
    &-x {
      ${buttonStyle};
    }
    &-action {
      ${buttonStyle};
      text-decoration: underline;
      margin-right: 1.5rem;
    }
    &__wrapper {
      ${setFlex()};
    }
  }
`
export const customButtonStyle = css`
  color: ${setColor.white};
  text-decoration: underline;
  padding-right: 0rem;
`

export const LearnMoreModal = styled.div`
  display: grid;
  grid-template-columns: 27.4rem 1fr;
  .text {
    &__wrapper {
      margin-top: 5.2rem;
    }
    &__container {
      height: 100%;
      ${setFlex('space-between', 'flex-start')};
      flex-direction: column;
      padding: 4.5rem 4rem;
      text-align: left;
      font-size: 1.4rem;
    }
    &__header {
      font-weight: bold;
      line-height: 0.89;
      font-size: 1.8rem;
      margin-bottom: 3.5rem;
    }
    &__list {
      line-height: 1.5;
      list-style-type: square;
      margin-left: 1.8rem;
      &::marker {
        width: 0.8rem;
        height: 0.8rem;
      }
    }
    &__example {
      line-height: 1.5;
      &.first {
        margin-top: 2rem;
      }
    }
  }
  .gif {
    height: 41.3rem;
    &__wrapper {
      width: 27.4rem;
      height: 49.1rem;
      ${setFlex()};
      background-color: ${setColor.grayWhite};
    }
  }
`
