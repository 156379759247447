import request, { POSTOption } from './requestAxios'
import { BROADCAST_API, ENDPOINT_KEY } from './api'

const uploadFileRequest = (file) => {
  const formData = new FormData()
  formData.append('file', file)

  return request(
    `${BROADCAST_API}/upload?${ENDPOINT_KEY}`,
    POSTOption(formData)
  )
}

const _toggleText = (primary, secondary) =>
  (primary.length === 0 ? secondary : primary)

const _formatDataAttr = (type, embedUrl, url, promoCode) => {
  let data = {}
  if(type === 'image') {
    data = { link: _toggleText(embedUrl, url) }
  } else if(type === 'coupon') {
    data = { promotion_code: promoCode }
  }
  return data
}

const _formatMediaData = ({
  type,
  url,
  embedUrl,
  dimensions,
  imageThumbnail,
  promoCode
}) => {
  const { width, height } = dimensions
  const data = _formatDataAttr(type, embedUrl, url, promoCode)
  return {
    image: {
      url
    },
    thumbnail: {
      url: type === 'video' ? imageThumbnail : url,
      height,
      width
    },
    data
  }
}

const isBlobUrl = (url) => typeof url === 'string' && url.startsWith('blob:')

const _handleUploadFile = async (file) => {
  let fileUrl
  if(file instanceof Blob) {
    fileUrl = await uploadFileRequest(file)
  } else if(typeof file === 'string' && !isBlobUrl(file)) {
    fileUrl = { url: file }
  } else {
    throw new Error('upload file failed')
  }
  return fileUrl.url
}

export const _formatBodyForBroadcastAPI = async ({
  broadcastId,
  broadcastAt,
  customType,
  media,
  type,
  receiver,
  message,
  notificationMessage,
  embedUrl,
  mediaDimensions,
  thumbnail,
  promoCode
}, mode) => {
  let mediaBody,
    url
  if(customType !== 'text') {
    url = await _handleUploadFile(media)
  }
  if(customType === 'broadcast_image') {
    mediaBody = _formatMediaData({
      type: 'image', url, embedUrl, dimensions: mediaDimensions
    })
  } else if(customType === 'broadcast_video') {
    mediaBody = _formatMediaData({
      type: 'video', url, embedUrl, imageThumbnail: thumbnail.url, dimensions: { ...thumbnail }
    })
  } else if(customType === 'broadcast_m-coupon') {
    mediaBody = _formatMediaData({
      type: 'coupon', url, promoCode, dimensions: mediaDimensions
    })
  }
  const broadcastIdAttr = mode === 'edit' ? { broadcast_id: broadcastId } : {}
  return {
    ...broadcastIdAttr,
    broadcast_at: broadcastAt,
    type,
    platform: 'sendbird',
    ...receiver,
    messages: [
      {
        message: _toggleText(message, notificationMessage),
        notification_message: _toggleText(notificationMessage, message),
        custom_type: customType,
        ...mediaBody
      }
    ]
  }
}
