import { createAsyncThunk } from '@reduxjs/toolkit'
import queryString from 'query-string'
import { BROADCAST_API, ENDPOINT_KEY } from '../../utils/api'
import request, {
  DELETEOption,
  GETOption,
  POSTOption
} from '../../utils/requestAxios'

const _startDay = (date) => date.setHours(0, 0, 0, 0)

const _endDay = (date) => date.setHours(23, 59, 59, 999)

const _setStartEndDay = (date, type) =>
  (type === 'start' ? _startDay(new Date(date)) : _endDay(new Date(date)))

const isDateExist = (date, type) => {
  let tempDate = date
  if(date) {
    tempDate = _setStartEndDay(date, type)
    tempDate = new Date(tempDate).toISOString()
  }
  return tempDate
}

const _formatDate = (startDate, endDate) => {
  const tempEndDate = !endDate ? startDate : endDate
  const dates = {
    start: isDateExist(startDate, 'start'),
    end: isDateExist(tempEndDate, 'end')
  }
  return dates
}

const _getQueryString = async (payload) => {
  const dates = await _formatDate(payload?.startDate, payload?.endDate)
  const queryStr = queryString.stringify({
    start_date: dates.start,
    end_date: dates.end,
    limit: payload?.limit || 10,
    page: payload?.page || 1
  })

  return queryStr
}

export const getBroadcastList = createAsyncThunk(
  'broadcast/getBroadcastList',
  async (payload) => {
    const queryStr = await _getQueryString(payload)
    const path = `${BROADCAST_API}/pending?${queryStr}&${ENDPOINT_KEY}`
    const response = await request(path, GETOption())
    return response
  }
)

export const getSentBroadcastList = createAsyncThunk(
  'broadcast/getSentBroadcastList',
  async (payload) => {
    const queryStr = await _getQueryString(payload)
    const path = `${BROADCAST_API}/completed?${queryStr}&${ENDPOINT_KEY}`
    const response = await request(path, GETOption())
    return response
  }
)

export const getFailedBroadcastList = createAsyncThunk(
  'broadcast/getFailedBroadcastList',
  async (payload) => {
    const queryStr = await _getQueryString(payload)
    const path = `${BROADCAST_API}/unsuccessful?${queryStr}&${ENDPOINT_KEY}`
    const response = await request(path, GETOption())
    return response
  }
)

const _getNewList = async (menu, dispatch) => {
  switch (menu) {
    case 'broadcast':
      await dispatch(getBroadcastList())
      break
    case 'failed':
      await dispatch(getFailedBroadcastList())
      break
    default:
      break
  }
}

export const deleteBroadcast = createAsyncThunk(
  'broadcast/delete',
  async (broadcast, { dispatch }) => {
    const path = `${BROADCAST_API}/${broadcast.id}`
    const response = await request(path, DELETEOption())
    await _getNewList(broadcast.activeMenu, dispatch)
    return response
  }
)

export const resendBroadcastRequest = createAsyncThunk(
  'broadcast/resendBroadcastRequest',
  // eslint-disable-next-line camelcase
  async (broadcast_id, { dispatch }) => {
    const path = `${BROADCAST_API}/recover?${ENDPOINT_KEY}`
  // eslint-disable-next-line camelcase
    const response = await request(path, POSTOption({ broadcast_id }))
    await _getNewList('broadcast', dispatch)
    return response
  }
)
