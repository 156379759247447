import React from 'react'
import PropTypes from 'prop-types'

import { InfoButton } from '../Button'
import SmartReplyInput from './SmartReplyInput'
import { generateTestId } from '../../utils/tools'

const SmartReplySection = ({
  smartReply,
  smartReplyInputRef,
  handleOnSmartReplyChange,
  handleEnterKeyAdd,
  removeSmartReply,
  removeAllSmartReply,
  addSmartReplyField
}) => {
  const _renderSmartReplyList = () => {
    const smartReplyTextField = smartReply.map((word, index) => (
      <SmartReplyInput
        word={word}
        index={index}
        smartReplyInputRef={smartReplyInputRef}
        onChange={(e) => handleOnSmartReplyChange(e, index)}
        onKeyUp={(e) => handleEnterKeyAdd(e.keyCode)}
        onRemove={() => removeSmartReply(index)}
        // eslint-disable-next-line react/no-array-index-key
        key={`smartReply-${index}`}
        id={generateTestId('greetingMessageEditor', 'input', 'smartReply')}

      />
    ))
    return smartReplyTextField
  }

  return (
    <div className='smartReply__container'>
      <div className='smartReply__header'>
        <span
          data-testid='greeting-subheader-smartReply'
          id={generateTestId('greetingMessageEditor', 'subHeader', 'smartReply')}
        >
          สมาร์ทรีพลาย
        </span>
        <div className='smartReply__header--icon'>
          <InfoButton
            theme='light_short'
            text='สมาร์ทรีพลายรองรับได้ไม่เกิน 25 ตัวอักษร'
            id={generateTestId('greetingMessageEditor', 'tooltip', 'suggestion')}
          />

          <button
            type='submit'
            className='smartReply__header--icon_btn'
            onClick={() => removeAllSmartReply()}
            data-testid='smartReply-delete-all-button'
            id={generateTestId(
              'greetingMessageEditor',
              'button',
              'deleteAllSmartReply'
            )}
          >
            <div
              className='binIcon'
              id={generateTestId(
                'greetingMessageEditor',
                'img',
                'deleteAllSmartReply'
              )}
            />
          </button>
        </div>
      </div>
      <div className='smartReply__wrapper'>
        {_renderSmartReplyList()}
        {smartReply.length < 4 && (
          <button
            type='submit'
            onClick={() => addSmartReplyField()}
            className='smartReply__input--btn_add'
            data-testid='input-smartReply-add'
            id={generateTestId('greetingMessageEditor', 'button', 'addSmartReply')}
          >
            เพิ่มสมาร์ทรีพลาย
          </button>
        )}
      </div>
    </div>
  )
}

export default SmartReplySection

SmartReplySection.propTypes = {
  smartReply: PropTypes.arrayOf(PropTypes.object),
  smartReplyInputRef: PropTypes.shape({}),
  handleOnSmartReplyChange: PropTypes.func,
  handleEnterKeyAdd: PropTypes.func,
  removeSmartReply: PropTypes.func,
  removeAllSmartReply: PropTypes.func,
  addSmartReplyField: PropTypes.func
}
