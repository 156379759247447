import React from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import {
  setSnackBar,
  setNotificationBar,
  setTimeoutModal,
  selectNotificationBar,
  selectTimeoutModal
} from './MainSlice'
import { selectUserProfile } from '../App/appSlice'
import Main from '.'

const MainConnector = ({ history, logout }) => {
  const dispatch = useDispatch()
  const notificationBarProps = useSelector(selectNotificationBar)
  const timeoutModalProps = useSelector(selectTimeoutModal)
  const userInfo = useSelector(selectUserProfile)

  return (
    <Main
      history={history}
      notificationBarProps={notificationBarProps}
      timeoutModalProps={timeoutModalProps}
      userInfo={userInfo}
      logout={logout}
      setSnackBar={(props) => dispatch(setSnackBar(props))}
      setNotificationBar={(props) => dispatch(setNotificationBar(props))}
      setTimeoutModal={(props) => dispatch(setTimeoutModal(props))}
    />
  )
}

export default MainConnector

MainConnector.propTypes = {
  history: PropTypes.shape({}),
  logout: PropTypes.func
}
