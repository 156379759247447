/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { GreetingTextarea } from './styled'
import { generateTestId } from '../../utils/tools'
import Skeleton from '../Skeleton'

const MessageSection = ({
  greetingItem,
  messageState,
  setMessageState,
  isLoading
}) => {
  const [placeholder, setPlaceholder] = useState('ใส่ข้อความทักทาย')
  const [disabled, setDisabled] = useState(true)
  const messageInputRef = useRef(null)
  const MESSAGE_MAX_LENGTH = 500
  const _formatIsMassageLong = (message) => {
    if(message.length > MESSAGE_MAX_LENGTH) {
      const sliced = message.slice(0, MESSAGE_MAX_LENGTH)
      setMessageState(sliced)
    }
  }
  const _handlePlaceHolder = () => {
    let tempPlaceholder,
      tempDisabled
    switch (greetingItem.status) {
      case 'succeeded':
        tempPlaceholder = 'ใส่ข้อความทักทาย'
        tempDisabled = false
        break
      case 'failed':
        tempDisabled = true
        break
      default:
        tempPlaceholder = 'Loading...'
        tempDisabled = true
        break
    }
    setPlaceholder(tempPlaceholder)
    setDisabled(tempDisabled)
  }

  useEffect(() => {
    _formatIsMassageLong(messageState)
  }, [messageState])

  useEffect(() => {
    _handlePlaceHolder()
  }, [greetingItem.status])

  const onMessageChange = (e) => {
    if(messageState.length <= MESSAGE_MAX_LENGTH) {
      setMessageState(e.target.value)
    }
  }

  const _handleLoadingState = () => {
    let component
    if(isLoading) {
      component = <Skeleton width='100%' height='16rem' />
    } else {
      component = (
        <div className='message__container'>
          <div className='message__header'>
            <span
              data-testid='greeting-subheader-message'
              id={generateTestId('greetingMessageEditor', 'subHeader', 'message')}
            >
              ข้อความ
            </span>
            <span
              className='message__header-count'
              data-testid='greeting-message-count'
              id={generateTestId('greetingMessageEditor', 'text', 'wordCount')}
            >
              {messageState.length}/{MESSAGE_MAX_LENGTH}
            </span>
          </div>
          <textarea
            className='message__textarea'
            value={messageState}
            placeholder={placeholder}
            onChange={(e) => onMessageChange(e)}
            onKeyDown={(e) => onMessageChange(e)}
            maxLength={MESSAGE_MAX_LENGTH}
            ref={messageInputRef}
            data-testid='message-textarea'
            disabled={disabled}
            id={generateTestId('greetingMessageEditor', 'textarea', 'message')}
          />
        </div>
      )
    }
    return component
  }

  return <GreetingTextarea>{_handleLoadingState()}</GreetingTextarea>
}

export default MessageSection
MessageSection.propTypes = {
  greetingItem: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.object, PropTypes.string])),
  setMessageState: PropTypes.func,
  messageState: PropTypes.string,
  isLoading: PropTypes.bool
}
