import styled, { css } from 'styled-components'
import {
  setFlex,
  borderStyle,
  setColor,
  buttonStyle,
  setTransition
} from '../../globalStyled'
import broadcastGrey from '../../imgs/broadcast_grey.svg'
import broadcastGreen from '../../imgs/broadcast_green.svg'
import greetingGreen from '../../imgs/greeting_green.svg'
import greetingGrey from '../../imgs/greeting_grey.svg'
import profanityGrey from '../../imgs/profanity_grey.svg'
import profanityGreen from '../../imgs/profanity_green.svg'
import segmentGreen from '../../imgs/segment_green.svg'
import segmentGrey from '../../imgs/segment_grey.svg'
import logoutGreen from '../../imgs/logout_green.svg'
import logoutGrey from '../../imgs/logout_grey.svg'

const iconStyle = css`
  width: 2.4rem;
  height: 2.4rem;
  padding-right: 1rem;
`

export const SideNavWrapper = styled.div`
  border-right: ${borderStyle};
  ${setFlex('space-between')};
  flex-direction: column;
  padding: 1.4rem 0;
  .menu-list {
    padding: 3rem 0;
    width: 100%;
    .segment-item {
      margin-top: 20px;
    }
  }
  .link {
    ${buttonStyle};
    width: 100%;
    background-color: ${setColor.transparent};
    padding: 1.5rem 2.4rem;
    color: ${setColor.brownishGrey};
    text-align: left;
    ${setFlex('flex-start')};
    ${setTransition('0.05s')};
    &__wrapper {
      width: 100%;
    }
    span {
      padding-left: 0.8rem;
    }
    .broadcastIcon {
      background-image: url(${broadcastGrey});
      ${iconStyle};
      &.active {
        background-image: url(${broadcastGreen});
      }
    }
    .greetingIcon {
      background-image: url(${greetingGrey});
      ${iconStyle};
      &.active {
        background-image: url(${greetingGreen});
      }
    }
    .profanityIcon {
      background-image: url(${profanityGrey});
      ${iconStyle};
      &.active {
        background-image: url(${profanityGreen});
      }
    }
    .segmentIcon {
      background-image: url(${segmentGrey});
      ${iconStyle};
      &.active {
        background-image: url(${segmentGreen});
      }
    }
    .logoutIcon {
      background-image: url(${logoutGrey});
      ${iconStyle};
      &.active {
        background-image: url(${logoutGreen});
      }
    }
    &.logout{
      padding-bottom:2.4rem;
    }
    &.active {
      color: ${setColor.primaryGreen};
    }
  }
  .header {
    img {
      height: 3.6rem;
    }
    &-wrapper {
      ${setFlex('flex-start')};
      padding: 0 2.4rem;
      img {
        width: 3.6rem;
        height: 3.6rem;
      }
    }
    &-text {
      font-size: 16px;
      font-weight: 500;
      color: ${setColor.black};
      margin-left: 1.2rem;
      height: 3.6rem;
      ${setFlex('space-between', 'flex-start')};
      flex-direction: column;
    }
  }
  .logout__wrapper {
    padding-top: 1.8rem;
    width: 100%;
  }
  .version{
    padding:1.05rem 2.4rem 1.05rem 2.8rem;
    font-size:1rem;
    color:${setColor.veryLightPink};
  }
`
