import styled from 'styled-components'
import {
  setColor,
  borderStyle,
  setFlex,
  scrollBarStyle,
  setTransition
} from '../../globalStyled'

export const PreviewWrapper = styled.div`
  border-left: ${borderStyle};
  display: grid;
  grid-template-rows: 7.6rem 1fr;

  .bubble {
    &__wrapper {
      max-height: calc(100vh - 18.1rem);
      overflow-y: auto;
      padding: 2.6rem;
      ${scrollBarStyle};
    }
    word-wrap: break-word;
    white-space: pre-line;
    border-radius: 0rem 2.4rem 2.4rem 2.4rem;
    background-color: ${setColor.white};
    box-shadow: 0 0 20px 0 rgba(153, 153, 153, 0.2);
    overflow: hidden;
    font-size: 1.4rem;
    color: ${setColor.brownishGrey};
    height: ${(props) => (props.isLoading ? '15rem;' : ' fit-content')};
    opacity: 1;
    ${setTransition()};
    &__message {
      padding: 1rem 1.6rem;
      line-height: 1.43;
      min-height: 4rem;
      ${setTransition()};

      &.img {
        img,video {
          height: 100%;
          width: 26.4rem;
          display: block;
        }
        padding: 0rem;
      }
    }
    &__loading {
      padding: 1rem 1.6rem;
      line-height: 1.43;
      ${setFlex('space-between', 'flex-start')};
      flex-direction: column;
      height: 15rem;
    }
    &__smartReply {
      ${setFlex()};
      text-align: center;
      height: 4.1rem;
      border-top: ${borderStyle};
      border-color: ${setColor.grayWhite};
      ${setTransition()};
    }
  }
`
