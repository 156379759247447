import styled, { css } from 'styled-components'
import {
  setColor,
  setTransition,
  setFlex,
  scrollBarStyle,
  borderStyle,
  buttonStyle
} from '../../globalStyled'
import arrowIcon from '../../imgs/arrow_up__grey.svg'

const _getHeight = ({
 isActive, height, maxHeight, minHeight
}) => {
  let style = css`
    height: '0rem';
  `
  if(isActive && !maxHeight) {
    style = css`
      height: ${height};
    `
  } else if(isActive && maxHeight) {
    style = css`
      max-height: ${maxHeight};
      min-height: ${minHeight};
    `
  }
  return style
}

export const ExpandCollapseGroupWrapper = styled.div`
  ${setTransition()};
  ${setFlex('flex-start')};
  flex-direction: column;
  width: ${(props) => props.width};
  font-size: 1.2rem;
  color: ${setColor.brownishGrey};

  ${(props) => _getHeight({ ...props })};
  border: ${(props) => (props.isActive ? borderStyle : 'none')};
  ${(props) => props.customStyle};
  .label {
    ${buttonStyle};
    width: 100%;
    padding: ${(props) => (props.isActive ? '0rem 0rem 0.5rem 0.8rem;' : '0rem')};
    ${setFlex('space-between')};
    ${setTransition()};



    &__arrow {
      background-image: url(${arrowIcon});
      background-repeat: no-repeat;
      height: 1.6rem;
      width: 1.6rem;
      margin-right: 0.4rem;
      ${setTransition()};
      transform: ${(props) =>
        (props.isActive ? 'rotate(0deg)' : 'rotate(180deg)')};
    }
  }
`

export const List = styled.div`
  white-space: nowrap;
  display: block;
  line-height: 1.6rem;
  padding-bottom: 0.8rem;
  ${setTransition()};
  width: calc(${(props) => props.width} - 3.2rem);

  ${(props) => props.customStyle};
`
export const ListWrapper = styled.div`
  ${setTransition()};
  ${setFlex('flex-start', 'flex-start')};
  text-align:left;
  ${scrollBarStyle};
  width: ${(props) => props.width};
  padding: 0rem 1.6rem;
  flex-direction: column;
  overflow: ${(props) => (props.isActive ? 'auto' : 'hidden')};
  visibility: ${(props) => (props.isActive ? 'visible' : 'hidden')};
  overflow-x: hidden;
  ${(props) => props.customStyle};
`
