import React from 'react'
import PropTypes from 'prop-types'
import { PreviewWrapper } from './styled'
import { generateTestId } from '../../utils/tools'
import Skeleton from '../Skeleton'

const Preview = ({
 message, smartReply = [], isLoading, mediaUrl
}) => {
  const isShowPreview = () => {
    const isSmartReplyExits = smartReply.length > 0
    const isSmartReplyNotEmpty =
      isSmartReplyExits &&
      smartReply.filter((item) => item.text !== '').length !== 0
    const isShow = message.length !== 0 || mediaUrl || isSmartReplyNotEmpty
    return isShow
  }

  const _renderSmartReplies = () =>
    smartReply.map(
      (word, index) =>
        word.text !== '' && (
          <div
            // eslint-disable-next-line react/no-array-index-key
            key={`${word.text}-${index}`}
            className='bubble__smartReply'
            data-testid={`preview-smartReply-${index}`}
            id={generateTestId('preview', 'text', 'previewSmartReply', index)}
          >
            {word.text}
          </div>
        )
    )

  const _renderMainMessage = (messageItem, className) => (
    <div
      className={`bubble__message${className}`}
      data-testid='preview-message'
      id={generateTestId('preview', 'text', 'previewMessage')}
    >
      {messageItem}
    </div>
  )

  const _renderPreviewByType = () => {
    let item = message,
      imageClassName = ''
    if(mediaUrl) {
      item = (
        <img
          data-testid='preview-image'
          src={mediaUrl}
          alt=''
          id={generateTestId('preview', 'image', 'previewImage')}
        />
      )
      imageClassName = ' img'
    }

    return (
      <>
        {_renderMainMessage(item, imageClassName)}
        {_renderSmartReplies()}
      </>
    )
  }

  const _handleState = () => {
    let component
    if(isLoading) {
      const skeletonStyle = {
        height: '1.4rem',
        customStyle: 'margin-bottom:0.5rem;'
      }
      component = (
        <div
          className='bubble__loading'
          data-testid='preview-message'
          id={generateTestId('preview', 'skeleton', 'previewMessage')}
        >
          <Skeleton {...skeletonStyle} />
          <Skeleton {...skeletonStyle} width='60%' />
          <Skeleton {...skeletonStyle} width='80%' />
          <Skeleton {...skeletonStyle} width='70%' />
          <Skeleton {...skeletonStyle} />
        </div>
      )
    } else if(isShowPreview()) {
      component = _renderPreviewByType()
    }

    return (
      <div className='bubble__wrapper' data-testid='preview-bubble'>
        <div
          className='bubble'
          id={generateTestId('preview', 'wrapper', 'bubble')}
        >
          {component}
        </div>
      </div>
    )
  }
  return (
    <PreviewWrapper isLoading={isLoading}>
      <div
        className='header'
        data-testid='preview-header'
        id={generateTestId('preview', 'subHeader', 'preview')}
      >
        ดูตัวอย่าง
      </div>
      {_handleState()}
    </PreviewWrapper>
  )
}

export default Preview

Preview.propTypes = {
  message: PropTypes.string,
  smartReply: PropTypes.arrayOf(PropTypes.object),
  isLoading: PropTypes.bool,
  mediaUrl: PropTypes.string
}
