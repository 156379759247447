import { createAsyncThunk } from '@reduxjs/toolkit'
import request, { POSTOption, GETOption } from '../../utils/requestAxios'
import { BROADCAST_API, ENDPOINT_KEY, SEGMENT_API } from '../../utils/api'
import { getBroadcastList } from '../BroadcastListPage/action'
import { resetCreateBroadcastDataState } from './createBroadcastSlice'
import { _formatBodyForBroadcastAPI } from '../../utils/broadcastActions'

export const uploadFileRequest = createAsyncThunk(
  'broadcast/uploadImage',
  async (file) => {
    const formData = new FormData()
    formData.append('file', file)
    const response = await request(
        `${BROADCAST_API}/upload?${ENDPOINT_KEY}`,
        POSTOption(formData)
      )
    return response
  }
)

export const createBroadcast = createAsyncThunk(
  'broadcast/createBroadcast',
  async (payload, thunkAPI) => {
    const body = await _formatBodyForBroadcastAPI(payload, 'create')
    const response = await request(
        `${BROADCAST_API}?${ENDPOINT_KEY}`,
        POSTOption(body)
      )
    await thunkAPI.dispatch(getBroadcastList())
    await thunkAPI.dispatch(resetCreateBroadcastDataState())

    return response
  }
)

export const getSegmentList = createAsyncThunk(
  'broadcast/getSegmentList',
  async () => {
    const response = await request(`${SEGMENT_API}`, GETOption())
    return response
  }
)

export const saveDraftBroadcast = createAsyncThunk(
  'broadcast/saveDraftBroadcast',
  async () => ({})
)
