import { createAsyncThunk } from '@reduxjs/toolkit'
import firebase from 'firebase/app'
import { setErrorMessage, load } from './loginSlice'
import request, { POSTWithNoAuthOption } from '../../utils/requestAxios'
import { LOGIN_API } from '../../utils/api'
import auth from '../../firebase'

export const loginSAML = createAsyncThunk(
  'login/loginSAML',
  async (idToken) => {
    const data = {
      access_token: idToken
    }
    const response = await request(LOGIN_API, POSTWithNoAuthOption(data))
    return response
  }
)

export const getFirebaseIdToken = (dispatch) => {
  auth.currentUser
    .getIdToken(true)
    .then((idToken) => {
      dispatch(loginSAML(idToken))
    })
    .catch(() => {
      dispatch(load(false))
      dispatch(setErrorMessage('เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง'))
    })
}

export const signin = (dispatch) => {
  const provider = new firebase.auth.SAMLAuthProvider('saml.cpall')

  dispatch(load(true))
  return auth
    .signInWithPopup(provider)
    .then(() => {
      dispatch(setErrorMessage(''))
      getFirebaseIdToken(dispatch)
    })
    .catch((error) => {
      dispatch(load(false))
      switch (error.code) {
        case 'auth/popup-closed-by-user':
          dispatch(
            setErrorMessage(
              'ป๊อปอัปถูกปิดโดยผู้ใช้ก่อนที่จะสิ้นสุดการดำเนินการ'
            )
          )
          break
        default:
          dispatch(setErrorMessage('เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง'))
          break
      }
    })
}
