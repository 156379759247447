/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Bar, SnackbarWrapper } from './styled'
import { setColor } from '../../globalStyled'
import errorIcon from '../../imgs/error_white.svg'
import warnIcon from '../../imgs/caution_white.svg'
import successIcon from '../../imgs/success_white.svg'
import closeIcon from '../../imgs/close_white.svg'
import { generateTestId } from '../../utils/tools'

const TEN_SECS = 10000
const SnackbarCustom = ({
  text,
  type,
  isActive,
  duration = TEN_SECS,
  showAt,
  history,
  onClose = () => {}
}) => {
  let stateItem
  switch (type) {
    case 'warn':
      stateItem = { backgroundColor: setColor.yellow, icon: warnIcon }
      break
    case 'error':
      stateItem = { backgroundColor: setColor.cherryRed, icon: errorIcon }
      break
    default:
      stateItem = { backgroundColor: setColor.jungleGreen, icon: successIcon }
      break
  }
  const [isShow, setIsShow] = useState(isActive)
  useEffect(() => {
    setIsShow(isActive)
  }, [isActive])

  const onHide = () => {
    setIsShow(false)
    onClose()
  }

  useEffect(() => {
    if(showAt && showAt !== history.location.pathname) {
      onHide()
    }
  }, [history.location.pathname])

  useEffect(() => {
    let timer
    if(isShow) {
      timer = setTimeout(() => onHide(), duration)
    }
    return () => {
      clearTimeout(timer)
    }
  }, [isShow, duration, type, onHide])
  return (
    <SnackbarWrapper isActive={isShow}>
      <Bar
        backgroundColor={stateItem.backgroundColor}
        isActive={isShow}
        data-testid='snackbar'
        type={type}
        id={generateTestId('snackbar', 'wrapper', '')}
      >
        <img
          src={stateItem.icon}
          alt=''
          id={generateTestId('snackbar', 'img', 'icon')}
          className='icon'
        />
        <div
          className='text'
          data-testid='snackbar-text'
          id={generateTestId('snackbar', 'text', 'message')}
        >
          {text}
        </div>
        <button
          type='submit'
          className='btn-x'
          onClick={() => onHide()}
          data-testid='snackbar-x-btn'
          key='x-button'
          id={generateTestId('snackbar', 'button', 'x')}
        >
          <img
            src={closeIcon}
            alt=''
            id={generateTestId('snackbar', 'close', 'icon')}
            className='icon'
          />
        </button>
      </Bar>
    </SnackbarWrapper>
  )
}

export default SnackbarCustom

SnackbarCustom.propTypes = {
  text: PropTypes.string,
  type: PropTypes.string,
  isActive: PropTypes.bool,
  duration: PropTypes.number,
  showAt: PropTypes.string,
  onClose: PropTypes.func,
  history: PropTypes.shape({
    location: PropTypes.shape({
      pathname: PropTypes.string
    })
  })
}
