const END_POINT = process.env.REACT_APP_API_ENDPOINT
const KEY = process.env.REACT_APP_API_KEY

export const GREETING_API = `${END_POINT}/greeting`
export const LOGIN_API = `${END_POINT}/login`
export const BROADCAST_API = `${END_POINT}/broadcast`
export const PROFANITY_API = `${END_POINT}/profanity/keywords`
export const ME_API = `${END_POINT}/me`
export const SEGMENT_API = `${END_POINT}/segments`
export const ENDPOINT_KEY = `key=${KEY}`
