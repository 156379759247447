import React from 'react'
import PropTypes from 'prop-types'
import { generateTestId } from '../../utils/tools'
import { PrimaryButton } from '../Button'
import { HeaderActionWrapper } from './styled'

const HeaderAction = ({
  onPrimaryClick,
  onSecondaryClick,
  primaryResponse,
  secondaryResponse,
  primaryButton = '',
  secondaryButton = '',
  customHeader
}) => (
  <HeaderActionWrapper>
    <div
      id={generateTestId('headerAction', 'text', 'customText')}
    >{customHeader}
    </div>
    <div className='button-wrapper'>
      {onPrimaryClick && (
        <PrimaryButton
          onClick={() => onPrimaryClick()}
          isLoading={primaryResponse?.status === 'loading'}
          testid='headerAction-button-primary'
          name='primary'
          id={generateTestId('headerAction', 'button', 'primary')}
        >
          {primaryButton}
        </PrimaryButton>
        )}
      {onSecondaryClick && (
        <PrimaryButton
          customStyle='margin-right:0.8rem'
          onClick={() => onSecondaryClick()}
          isLoading={secondaryResponse?.status === 'loading'}
          testid='headerAction-button-secondary'
          name='secondary'
          id={generateTestId('headerAction', 'button', 'secondary')}
        >
          {secondaryButton}
        </PrimaryButton>
        )}
    </div>
  </HeaderActionWrapper>
  )

export default HeaderAction

HeaderAction.propTypes = {
  onPrimaryClick: PropTypes.func,
  onSecondaryClick: PropTypes.func,
  primaryResponse: PropTypes.shape({
    status: PropTypes.string
  }),
  secondaryResponse: PropTypes.shape({
    status: PropTypes.string
  }),
  primaryButton: PropTypes.string,
  secondaryButton: PropTypes.string,
  customHeader: PropTypes.oneOfType([PropTypes.element])
}
