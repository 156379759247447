import React from 'react'
import PropTypes from 'prop-types'
import { Route, Router, Switch } from 'react-router-dom'
import history from '../../history'
import MainPage from '../MainPage/MainConnector'
import LoginPage from '../LoginPage/LoginpageConnector'
import { GlobalStyle } from '../../globalStyled'
import { getToken, getStatus } from '../../utils/tokenService'
import { generateTestId } from '../../utils/tools'
import { LoadingWrapper } from './styled'

const App = ({ auth, userProfile, logout }) => {
  const renderComponent = () => {
    const hasToken = getToken()?.length > 0
    const isLoginSuccess = hasToken && getStatus() === 'true'
    if(
      auth === 'load' ||
      userProfile?.status === 'loading'
    ) {
      return (
        <LoadingWrapper>
          <div className='loader' id={generateTestId('App', 'text', 'loading')}>LOADING...</div>
        </LoadingWrapper>
      )
    } if(auth && isLoginSuccess) {
      return (
        <Route
          path='/'
          component={() => (
            <MainPage logout={logout} history={history} />
          )}
        />
      )
    }
    return (
      <Switch>
        <Route path='/'>
          <LoginPage isLoginSuccess={isLoginSuccess} />
        </Route>
      </Switch>
    )
  }

  return (
    <Router history={history}>
      <GlobalStyle />
      {renderComponent()}
    </Router>
  )
}

export default App

App.propTypes = {
  auth: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  userProfile: PropTypes.shape({
    status: PropTypes.string
  }),
  logout: PropTypes.func
}
