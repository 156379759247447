import { createSlice } from '@reduxjs/toolkit'
import { setToken, setStatus, clearToken } from '../../utils/tokenService'
import { loginSAML } from './actions'

const initialState = () => ({
  errorMessage: '',
  loading: false,
  loginSAML: {
    status: 'idle',
    response: {}
  },
  userInfo: {}
})

export const loginSlice = createSlice({
  name: 'login',
  initialState: initialState(),
  reducers: {
    resetLogin: () => initialState(),
    setErrorMessage: (state, action) => {
      state.errorMessage = action.payload
    },
    load: (state, action) => {
      state.loading = action.payload
    }
  },
  extraReducers: {
    [loginSAML.pending]: (state) => {
      state.loginSAML.status = 'loading'
    },
    [loginSAML.fulfilled]: (state, action) => {
      state.loginSAML.status = 'succeeded'
      state.loginSAML.response = action.payload
      state.loading = false
      state.userInfo = action.payload.authentication_info
      setStatus(true)
      setToken(action.payload.token)
    },
    [loginSAML.rejected]: (state, action) => {
      state.loginSAML.status = 'failed'
      state.loginSAML.response = action.error.message
      state.errorMessage = 'เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง'
      state.loading = false
      setStatus(false)
      clearToken()
    }
  }
})

export const { resetLogin, setErrorMessage, load } = loginSlice.actions

export const selectErrorMessage = (state) => state.login.errorMessage
export const selectLoad = (state) => state.login.loading
export const selectUserInfo = (state) => state.login.userInfo

export default loginSlice.reducer
