import { configureStore } from '@reduxjs/toolkit'
import mainReducer from './containers/MainPage/MainSlice'
import greetingReducer from './containers/GreetingMessagePage/greetingSlice'
import loginReducer from './containers/LoginPage/loginSlice'
import appReducer from './containers/App/appSlice'
import broadcastReducer from './containers/BroadcastListPage/broadcastListSlice'
import createBroadcastReducer from './containers/CreateBroadcastPage/createBroadcastSlice'
import editBroadcastReducer from './containers/EditBroadcastPage/editBroadcastSlice'
import profanityReducer from './containers/ProfanityWordPage/profanitySlice'
import segmentReducer from './containers/SegmentPage/segmentSlice'

export default configureStore({
  reducer: {
    main: mainReducer,
    greeting: greetingReducer,
    login: loginReducer,
    app: appReducer,
    broadcast: broadcastReducer,
    createBroadcast: createBroadcastReducer,
    editBroadcast: editBroadcastReducer,
    profanity: profanityReducer,
    segment: segmentReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    })
})
