import styled, { css } from 'styled-components'
import { setColor } from '../../globalStyled'

export const SkeletonWrapper = styled.div`
  display: inline-block;
  position: relative;
  overflow: hidden;
  background-color: ${setColor.grayWhite};
  border-radius: 3px;
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  ${(props) => css`
    ${props.customStyle}
  `}
  @keyframes shimmer {
    100% {
      transform: translateX(100%);
    }
  }
  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    width: 100%;
    background-image: linear-gradient(
      120deg,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0.3),
      rgba(255, 255, 255, 0.7),
      rgba(255, 255, 255, 0.3),
      rgba(255, 255, 255, 0)
    );
    animation: shimmer 0.5s infinite;
    content: '';
  }
`
