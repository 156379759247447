/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { GreetingMessageWrapper } from './styled'
import RouteLeavingGuard from '../../components/RouteLeavingGuard'
import { OverlayCustomModal } from '../../components/CustomModal'
import Preview from '../../components/Preview'
import GreetingMessageEditor from '../../components/GreetingMessageEditor'
import { generateTestId, setIsBlockSideMenuClick } from '../../utils/tools'

const GreetingMessage = ({
  history,
  greetingItem,
  updateGreetingItemRequest,
  updateGreetingResponse,
  isLoading,
  resetGreetingItemStatus
}) => {
  const [messageState, setMessageState] = useState(greetingItem.data.message)
  const [smartReplyState, setSmartReplyState] = useState(
    greetingItem.data.smartReply
  )
  const [isBlockRouting, setIsBlockRouting] = useState(false)
  const [isShowConfirmModal, setIsShowConfirmModal] = useState(false)

  const onSaveRequest = () => {
    const splittedLineBreakMessage = messageState.replace(
      /(?:\r\n|\r|\n)/g,
      '\n'
    )
    setIsShowConfirmModal(false)
    updateGreetingItemRequest({
      message: splittedLineBreakMessage,
      smartReply: smartReplyState
    })
  }

  const requiredFieldModal = {
    confirmButton: 'ตกลง',
    onConfirm: () => setIsShowConfirmModal(false),
    title: 'บันทึกข้อความไม่สำเร็จ',
    description: `ข้อความต้อนรับไม่ครบถ้วน 
กรุณาใส่ข้อความให้ครบและบันทึกใหม่อีกครั้ง`
  }

  const [confirmModal, setConfirmModal] = useState({})

  const _checkEachSmartReplyChanges = (smartReply) => {
    const isSameRange = smartReplyState.length !== smartReply.length
    const isSmartRepliesChange =
      JSON.stringify(smartReplyState) !== JSON.stringify(smartReply)
    return isSameRange || isSmartRepliesChange
  }

  const _setIsBlockRouting = (isBlock) => {
    setIsBlockRouting(isBlock)
    setIsBlockSideMenuClick(isBlock)
  }

  const _checkShouldBlock = () => {
    const { message, smartReply } = greetingItem.data
    const isSmartReplyChange = _checkEachSmartReplyChanges(smartReply)
    if(messageState !== message || isSmartReplyChange) {
      _setIsBlockRouting(true)
    } else {
      _setIsBlockRouting(false)
    }
  }

  useEffect(() => {
    _checkShouldBlock()
  }, [messageState, smartReplyState, greetingItem])

  const onSaveClicked = () => {
    _checkShouldBlock()
    if(messageState === '' || smartReplyState.some((e) => e.text === '')) {
      setConfirmModal(requiredFieldModal)
      setIsShowConfirmModal(true)
    } else {
      setIsShowConfirmModal(false)
      onSaveRequest()
    }
  }

  const onNotSaveAndRedirect = (path) => {
    _setIsBlockRouting(false)
    history.push(path.pathname)
  }

  const _handleNavigate = (path) => {
    if(path.pathname === history.location.pathname) {
      resetGreetingItemStatus()
    } else {
      history.push(path)
    }
  }

  return (
    <GreetingMessageWrapper>
      <RouteLeavingGuard
        when={isBlockRouting}
        navigate={(path) => _handleNavigate(path)}
        shouldBlockNavigation={isBlockRouting}
        onConfirm={(path) => onNotSaveAndRedirect(path)}
        title='ข้อมูลที่แก้ไขยังไม่ถูกบันทึก'
        description={`การออกจากหน้านี้จะทำให้ข้อมูลที่แก้ไขถูกยกเลิก 
        คุณต้องการออกจากหน้านี้หรือไม่`}
        confirmButton='ออกจากหน้านี้'
        cancelButton='ยกเลิก'
        response={updateGreetingResponse}
        history={history}
        id={generateTestId('greetingMessage', 'routeLeavingGuard', 'modal')}
      />
      <div
        className='header'
        data-testid='greeting-header'
        id={generateTestId('greetingMessage', 'header', 'greetingMessage')}
      >
        ข้อความทักทาย
      </div>

      <div className='body__wrapper column-2'>
        <GreetingMessageEditor
          greetingItem={greetingItem}
          message={messageState}
          setMessage={setMessageState}
          smartReply={smartReplyState}
          setSmartReply={setSmartReplyState}
          headerAction={greetingItem.data.headerAction}
          onSaveClicked={onSaveClicked}
          updateGreetingResponse={updateGreetingResponse}
          isLoading={isLoading}
        />
        <Preview
          message={messageState}
          smartReply={smartReplyState}
          isLoading={isLoading}
        />
      </div>

      <OverlayCustomModal {...confirmModal} isShow={isShowConfirmModal} />
    </GreetingMessageWrapper>
  )
}

export default GreetingMessage

GreetingMessage.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
    location: PropTypes.shape({
      pathname: PropTypes.string
    })
  }),
  isLoading: PropTypes.bool,
  greetingItem: PropTypes.exact({
    status: PropTypes.string,
    data: PropTypes.object,
    response: PropTypes.object
  }),
  updateGreetingResponse: PropTypes.exact({
    status: PropTypes.string,
    response: PropTypes.object
  }),
  getLastUpdateRequest: PropTypes.func,
  getGreetingItemRequest: PropTypes.func,
  updateGreetingItemRequest: PropTypes.func,
  resetGreetingItemStatus: PropTypes.func
}
