import React from 'react'
import { FooterWrapper } from './styled'
import { generateTestId } from '../../utils/tools'

const Footer = () => (
  <FooterWrapper
    data-testid='footer'
    id={generateTestId('footer', 'text', 'sertis')}
  >
    Powered by Sertis Copyright 2020
  </FooterWrapper>
)

export default Footer
