/* eslint-disable react-hooks/exhaustive-deps */

import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { resetScheduledListStatus } from '../../containers/BroadcastListPage/broadcastListSlice'
import { resetGreetingItemStatus } from '../../containers/GreetingMessagePage/greetingSlice'
import { resetProfanityItemStatus } from '../../containers/ProfanityWordPage/profanitySlice'
import allChat from '../../imgs/logo-all-chat.webp'
import { generateTestId, getIsBlockSideMenuClick } from '../../utils/tools'
import { SideNavWrapper } from './styled'
// import { resetUploadSegmentStatus } from '../../containers/SegmentPage/segmentSlice'
import packageInfo from '../../../package.json'

const SideNav = ({ history, logout, userInfo }) => {
  const dispatch = useDispatch()
  const [activeMenu, setActiveMenu] = useState(history.location.pathname)

  const _resetState = (current) => {
    switch (current) {
      case '/profanity':
        dispatch(resetProfanityItemStatus())
        break
      case '/greeting':
        dispatch(resetGreetingItemStatus())
        break
      case '/broadcast':
        dispatch(resetScheduledListStatus())
        break
      default:
        break
    }
    history.replace()
  }

  useEffect(() => {
    const { pathname, state } = history.location
    setActiveMenu(pathname)
    if(state?.isBlocked === false) {
      _resetState(pathname)
    }
  }, [history.location.pathname])

  // the comment below is for disable menu from task: https://sertiscorp.atlassian.net/browse/CGW-667
  const adminMenuList = [
    // {
    //   label: 'บรอดแคสต์',
    //   path: '/broadcast',
    //   logo: 'broadcastIcon',
    //   id: 'broadcast',
    //   resetStatus: () => dispatch(resetScheduledListStatus())
    // },
    {
      label: 'ข้อความทักทาย',
      path: '/greeting',
      logo: 'greetingIcon',
      id: 'greeting',
      resetStatus: () => dispatch(resetGreetingItemStatus())
    }
    // {
    //   label: 'คำไม่เหมาะสม',
    //   path: '/profanity',
    //   logo: 'profanityIcon',
    //   id: 'profanity',
    //   resetStatus: () => dispatch(resetProfanityItemStatus())
    // },
    // {
    //   label: 'จัดการกลุ่ม',
    //   path: '/segment',
    //   logo: 'segmentIcon',
    //   id: 'segment',
    //   resetStatus: () => dispatch(resetUploadSegmentStatus())
    // }
  ]

  const liveSupportMenuList = [
    {
      label: 'รีพอร์ต',
      path: '/report',
      logo: 'profanityIcon',
      id: 'report'
    }
  ]

  const logoutItem = [
    {
      label: 'ออกจากระบบ',
      logo: 'logoutIcon',
      path: '/logout',
      id: 'logout'
    }
  ]
  const _handlePathChange = (path) => {
    setActiveMenu(history.location.pathname)
    history.push(path)
  }

  const _checkShouldReset = (path) => {
    const isBlockRouting = getIsBlockSideMenuClick()
    const isCurrent = history.location.pathname === path
    let shouldReset = false
    if(isCurrent && !isBlockRouting) {
      shouldReset = true
    }
    return shouldReset
  }

  const onMenuClick = (type, path, resetStatus) => {
    if(type === 'logout') {
      logout()
    } else {
      _handlePathChange(path)
      const shouldReset = _checkShouldReset(path)
      if(shouldReset && resetStatus) {
        resetStatus()
      }
    }
  }

  const _getSegmentClassName = (menu) => {
    let className = ''
    if(menu.label === 'จัดการกลุ่ม') {
      className = ' segment-item'
    }
    return className
  }

  const _renderMenu = (menus, type) =>
    menus.map((menu) => {
      const isMenuActive = activeMenu.startsWith(menu.path) ? ' active' : ''
      return (
        <button
          type='submit'
          className={`link${isMenuActive}${_getSegmentClassName(menu)}${type === 'logout' ? ' logout' : ''}`}
          onClick={() => onMenuClick(type, menu.path, menu.resetStatus)}
          key={menu.path}
          data-testid={`sideNav-menu-${menu.path}`}
          id={generateTestId('sideNav', 'button', menu.id)}
        >
          <div
            className={`${menu.logo}${isMenuActive}`}
            id={generateTestId('sideNav', 'img', menu.id)}
          />
          <span id={generateTestId('sideNav', 'text', menu.id)}>
            {menu.label}
          </span>
        </button>
      )
    })

  const _getMenuList = () => {
    let menuList = _renderMenu(liveSupportMenuList)
    if(userInfo.userInfo?.user_group === 'management-admin') {
      menuList = _renderMenu(adminMenuList)
    }
    return (
      <div
        className='menu-list'
        id={generateTestId('sideNav', 'wrapper', 'menus')}
      >{menuList}
      </div>
    )
  }

  return (
    <SideNavWrapper>
      <div className='link__wrapper'>
        <div className='header-wrapper'>
          <img
            src={allChat}
            alt=''
            id={generateTestId('sideNav', 'img', '711')}
          />
          <div className='header-text'>
            <span id={generateTestId('sideNav', 'header', 'allChat')}>
              ALL Chat
            </span>
            <span id={generateTestId('sideNav', 'header', 'adminPanel')}>
              Admin Panel
            </span>
          </div>
        </div>
        {_getMenuList()}
      </div>
      <div className='logout__wrapper'>
        {_renderMenu(logoutItem, 'logout')}
        <div className='version' data-testid='version'>{packageInfo.version}</div>
      </div>
    </SideNavWrapper>
  )
}

export default SideNav

SideNav.propTypes = {
  history: PropTypes.shape({
    location: PropTypes.shape({
      pathname: PropTypes.string,
      state: PropTypes.shape({
        isBlocked: PropTypes.bool
      })
    }),
    push: PropTypes.func,
    replace: PropTypes.func
  }),
  logout: PropTypes.func,
  userInfo: PropTypes.shape({
    userInfo: PropTypes.shape({
      user_group: PropTypes.string
    })
  })
}
