/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { OverlayWrapper, CustomModalWrapper } from './styled'
import { PrimaryButton, FlatButton } from '../Button'
import errorIcon from '../../imgs/error.svg'
import ExpandCollapseGroup from '../ExpandCollapseGroup'
import { generateTestId } from '../../utils/tools'

const CustomModal = ({
  children,
  width = '36.8rem',
  height = '22.5rem',
  customStyle,
  icon = errorIcon,
  title,
  description,
  confirmButton,
  cancelButton,
  onCancel,
  onConfirm,
  isShow,
  noPadding,
  dropdown = {
    isShow: false,
    label: '',
    list: []
  }
}) => {
  const _handleHeight = (expand) => {
    let heightTemp = height
    if(dropdown.isShow && expand) {
      heightTemp = '38rem'
    } else if(dropdown.isShow && !expand) {
      heightTemp = '26.5rem'
    } else {
      heightTemp = height
    }
    return heightTemp
  }

  const [modalHeight, setModalHeight] = useState(_handleHeight())
  const [dropdownActive, setDropdownActive] = useState(false)

  useEffect(() => {
    setModalHeight(_handleHeight())
  }, [dropdown])

  const toggleDropDown = () => {
    setModalHeight(_handleHeight(!dropdownActive))
    setDropdownActive(!dropdownActive)
  }
  return (
    <CustomModalWrapper
      height={modalHeight}
      width={width}
      customStyle={customStyle}
      isShow={isShow}
      noPadding={noPadding}
      isDropdownActive={dropdown.isActive}
      data-testid='custom-modal-wrapper'
      id={generateTestId('customModal', 'wrapper', 'box')}
    >
      {children || (
        <>
          <img
            src={icon}
            className='icon'
            alt=''
            id={generateTestId('customModal', 'img', 'icon')}
          />
          <div
            className='title'
            data-testid='custom-modal-title'
            id={generateTestId('customModal', 'text', 'title')}
          >
            {title}
          </div>
          <div
            className='description'
            data-testid='custom-modal-description'
            id={generateTestId('customModal', 'text', 'description')}
          >
            {description}
            {dropdown.isShow && (
              <div
                className='description__group'
                id={generateTestId('customModal', 'wrapper', 'dropdown')}
              >
                <ExpandCollapseGroup
                  label={dropdown.label}
                  list={dropdown.list}
                  setIsActive={toggleDropDown}
                  isActive={dropdownActive}
                  height='13.9rem'
                  id={generateTestId('customModal', 'ExpandCollapseGroup', dropdown.label)}
                  customStyleList={{ 'padding-bottom': '0.6rem' }}
                />
              </div>
            )}
          </div>
          <div className='button__wrapper'>
            {cancelButton && (
              <FlatButton
                testid='custom-modal-cancel-btn'
                onClick={onCancel}
                customStyle='border-color:#cccccc;width:11.3rem;'
                id={generateTestId('customModal', 'button', 'cancel')}
              >
                {cancelButton}
              </FlatButton>
            )}
            {confirmButton && (
              <PrimaryButton
                testid='custom-modal-confirm-btn'
                onClick={onConfirm}
                name='confirm'
                customStyle='width:11.3rem;'
                id={generateTestId('customModal', 'button', 'confirm')}
              >
                {confirmButton}
              </PrimaryButton>
            )}
          </div>
        </>
      )}
    </CustomModalWrapper>
  )
}

export const OverlayCustomModal = ({
  children,
  backgroundColor = 'rgba(0,0,0,0.2)',
  customComponent,
  isShow,
  ...props
}) =>
  isShow && (
    <OverlayWrapper
      backgroundColor={backgroundColor}
      isShow={isShow}
      data-testid='overlay'
      id={generateTestId('customModal', 'wrapper', 'overlay')}

    >
      <CustomModal {...props}>{children}</CustomModal>
    </OverlayWrapper>
  )

OverlayCustomModal.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]),
  backgroundColor: PropTypes.string,
  customComponent: PropTypes.element,
  isShow: PropTypes.bool
}

export default CustomModal

CustomModal.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]),
  width: PropTypes.string,
  height: PropTypes.string,
  customStyle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  icon: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  confirmButton: PropTypes.string,
  cancelButton: PropTypes.string,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
  isShow: PropTypes.bool,
  noPadding: PropTypes.bool,
  dropdown: PropTypes.shape({
    isShow: PropTypes.bool,
    label: PropTypes.string,
    list: PropTypes.arrayOf(PropTypes.object)
  })
}
