import 'whatwg-fetch'
import axios from 'axios'
/**
 * Parses the JSON returned by a network request
 *
 * @param  {object} response A response from a network request
 *
 * @return {object}          The parsed JSON from the request
 */

/**
 * Checks if a network request came back fine, and throws an error if not
 *
 * @param  {object} response   A response from a network request
 *
 * @return {object|undefined} Returns either the response, or throws an error
 */

function checkAxiosStatus(response) {
  if(response.status >= 200 && response.status < 300) {
    return response.data
  }

  const error = new Error(response.statusText)
  error.response = response
  throw error
}

/**
 * Requests a URL, returning a promise
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 *
 * @return {object}           The response data
 */
export function getApplicationToken() {
  return localStorage.getItem('jwtToken')
}

export default function request(url, options) {
  return axios({ ...options, url }).then(checkAxiosStatus)
}

export const POSTOption = (body) => {
  const token = getApplicationToken()
  return {
    method: 'POST',
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      'management-authorization': `Bearer ${token}`
    },
    data: body
  }
}

export const PUTOption = (body) => {
  const token = getApplicationToken()
  return {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'management-authorization': `Bearer ${token}`
    },
    data: body
  }
}

export const DELETEOption = (body) => {
  const token = getApplicationToken()
  return {
    method: 'DELETE',
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      'management-authorization': `Bearer ${token}`
    },
    data: body
  }
}

export const POSTWithNoAuthOption = (body) =>
  ({
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  })

export const PATCHOption = (body) => {
  const token = getApplicationToken()
  return {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      'management-authorization': `Bearer ${token}`
    },
    body: JSON.stringify(body)
  }
}

export const PATCHWithNoAuthOption = (body) =>
  ({
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  })

export const POSTFileOption = (body) => {
  const token = getApplicationToken()
  return {
    method: 'POST',
    headers: {
      'management-authorization': `Bearer ${token}`
    },
    body
  }
}

export const GETOption = () => {
  const token = getApplicationToken()
  return {
    method: 'GET',
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      'management-authorization': `Bearer ${token}`
    }
  }
}

export const DELETEOptionWithData = (body) => {
  const token = getApplicationToken()
  return {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'management-authorization': `Bearer ${token}`
    },
    body: JSON.stringify(body)
  }
}
