/* eslint-disable react-hooks/exhaustive-deps */
import React, { lazy, Suspense } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { setUserProfile } from '../App/appSlice'
import { selectErrorMessage, selectLoad, selectUserInfo } from './loginSlice'
import { signin } from './actions'
import { isEmptyObject } from '../../utils/tools'

const LoginLoadable = lazy(() => import('.'))

const LoginPageConnector = ({ resendEmail }) => {
  const errorMessage = useSelector(selectErrorMessage)
  const loading = useSelector(selectLoad)
  const userInfo = useSelector(selectUserInfo)
  const dispatch = useDispatch()

  React.useEffect(() => {
    if(isEmptyObject(userInfo)) {
      dispatch(setUserProfile(userInfo))
    }
  }, [userInfo])

  return (
    <Suspense fallback={<div>Loading</div>}>
      <LoginLoadable
        loading={loading}
        login={signin}
        resendEmail={resendEmail}
        errorMessage={errorMessage}
      />
    </Suspense>
  )
}

export default LoginPageConnector

LoginPageConnector.propTypes = {
  resendEmail: PropTypes.string
}
